/* Copyright Levelise Ltd 2019-2023 */

.fleet-line-graph {
    margin: 2.5px 2.5px !important;
    height: 100%;
    /* width: calc(100% - 480px) !important; */
    flex: 1;
    background: white;
    border-radius: 3px;
    border: 1px solid rgba(187, 187, 187, 0.33);
    /* height: fit-content; */
}

.line-graph-header {
    display: flex;
    align-items: baseline;
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
    padding: 2px;
}

.selected-af {
    white-space: nowrap;
    padding: 0 10px;
    margin: 3px 10px 3px 5px;
    font-size: 0.8rem;
    font-weight: 600;
    border-right: 1px solid black;
}

.line-graphs-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

div.right-graphs-wrapper,
div.left-graphs-wrapper {
    width: 50%;
    height: auto;
    padding: 2.5px 3px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.af-records-chart {
    height: 45vh;
    background: white;
}

.line-graphs-wrapper {
    padding: 2.5px;
}

.fleet-chart-tabs {
    margin-right: 10px;
    margin-left: 3px;
}

.fleet-chart-tabs>span {
    font-size: 0.71rem;
    font-weight: 600;
    padding: 4px 8px 7px;
    white-space: nowrap;
    cursor: pointer;
}

.fleet-chart-view {
    background: white;
    border-top: 1px solid rgba(187, 187, 187, 0.33);
    border-left: 1px solid rgba(187, 187, 187, 0.33);
    border-right: 1px solid rgba(187, 187, 187, 0.33);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.battery-systems,
.hw-tanks {
    min-width: 100px;
    max-width: 200px;
    font-size: 0.7rem;
    font-weight: 600;
    margin-right: 5px;
}

.battery-systems .css-6q0nyr-Svg,
.hw-tanks .css-6q0nyr-Svg {
    height: 18px;
    width: 18px;
}

.battery-systems .css-ow28xv-indicatorContainer,
.battery-systems .css-i6mdlr-indicatorContainer,
.hw-tanks .css-ow28xv-indicatorContainer,
.hw-tanks .css-i6mdlr-indicatorContainer {
    padding: 0 5px;
}

div.fleet-dispatched-chart.af-records-chart,
div.fleet-energy-chart.af-records-chart {
    height: 90vh;
}

div.fleet-all-connected-chart.af-records-chart,
div.fleet-all-soc-chart.af-records-chart,
div.fleet-all-temperature-chart.af-records-chart,
div.fleet-system-connected-chart.af-records-chart,
div.fleet-system-soc-chart.af-records-chart,
div.fleet-system-temperature-chart.af-records-chart,
div.fleet-all-hw-flow-chart.af-records-chart,
div.fleet-all-hw-median-chart.af-records-chart,
div.fleet-all-hw-sensor-chart.af-records-chart,
div.fleet-system-hw-flow-chart.af-records-chart,
div.fleet-hw-median-chart.af-records-chart,
div.fleet-hw-sensor-chart.af-records-chart {
    height: calc(100% / 3 - 4px);
}

.ppmType,
.connectedType {
    right: 8px;
    display: flex;
    justify-content: center;
}

.ppmType>div,
.connectedType>div {
    display: flex;
    flex-direction: row;
    padding: 0 3px;
}

.ppmType>div>input,
.connectedType>div>input {
    height: 9px;
    width: 9px;
    margin: 1px 3px 0 0;
}

#safari .ppmType>div>input,
#safari .connectedType>div>input {
    height: 11px;
    width: 11px;
    margin: 2px 1px 0 0;
}

.ppmType label,
.connectedType label {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
    font-family: 'Inter-Medium';
}

#safari .ppmType label,
#safari .connectedType label { 
    font-size: 9px;
}

div.fleet-all-hw-sensor-chart.af-records-chart,
div.fleet-hw-sensor-chart.af-records-chart {
    padding-right: 50px;
    position: relative;
}

.sensorType {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sensorType>div {
    display: flex;
    flex-direction: row;
    padding: 3px 0;
    width: 100%;
}

.sensorType>div>input {
    height: 9px;
    margin: 1px 0.5px 0 0px;
    display: flex;
    flex-direction: column;
    border: 2px solid #bbbbbb;
    background-color: #e7e6e7;
}

.sensorType label {
    font-size: 0.65rem;
    font-weight: 500;
}

/* Performance view */
.fleet-performance>div.right-graphs-wrapper {
    width: 99.9%;
    padding: 2.5px;
}

.fleet-performance>div.left-graphs-wrapper {
    display: none;
}

.fleet-performance .fleet-demand-response-chart.af-records-chart {
    height: 100%;
}

.css-yikdrh-menu ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.css-yikdrh-menu ::-webkit-scrollbar {
    width: 6.5px;
    background-color: #F5F5F5;
}

.css-yikdrh-menu ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgb(208, 208, 208);
}

.battery-systems-dropdown-con{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px
}

@media screen and (min-width: 1881px) {
    div.af-records-chart {
        padding: 2.5px;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1840px),  (max-width: 1280px){

    div.right-graphs-wrapper,
    div.left-graphs-wrapper {
        width: 99%;
        padding: 2.5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-direction: column;
    }

    div.left-graphs-wrapper.af-view {
        height: 100%;
    }

    div.fleet-all-soc-chart.af-records-chart,
    div.fleet-all-temperature-chart.af-records-chart,
    div.fleet-system-soc-chart.af-records-chart,
    div.fleet-system-temperature-chart.af-records-chart,
    div.fleet-all-hw-median-chart.af-records-chart,
    div.fleet-all-hw-sensor-chart.af-records-chart,
    div.fleet-hw-median-chart.af-records-chart,
    div.fleet-hw-sensor-chart.af-records-chart,
    .fleet-ppm-chart.af-records-chart,
    .fleet-generation-chart.af-records-chart {
        margin-top: 5px;
    }

    div.left-graphs-wrapper.af-view div.fleet-dispatched-chart.af-records-chart,
    div.left-graphs-wrapper.af-view div.fleet-energy-chart.af-records-chart {
        height: 100%;
    }
}

@media (min-width: 1025px) and (max-width: 1840px) {

    .line-graphs-wrapper.fleet-graphs-wrapper {
        overflow-y: scroll;
    }

    .line-graphs-wrapper.fleet-graphs-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
    }

    .line-graphs-wrapper.fleet-graphs-wrapper::-webkit-scrollbar {
        width: 6.5px;
        background-color: #F5F5F5;
    }

    .line-graphs-wrapper.fleet-graphs-wrapper::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: rgb(208, 208, 208);
    }

    div.fleet-performance.line-graphs-wrapper.fleet-graphs-wrapper::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .fleet-line-graph {
        width: calc(100% - 10px) !important;
    }

    .line-graphs-wrapper.fleet-graphs-wrapper {
        height: 891px;
        padding: 2.5px;
    }
}


@media screen and (min-width: 1025px) {
    .line-graphs-wrapper.fleet-graphs-wrapper {
        height: calc(100% - 30px);
        padding: 2.5px 2.5px 30px 2.5px;
    }
}

@media screen and (max-width: 1840px) {
    .line-graph {
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 1280px) {
    div.af-records-chart {
        width: 100%;
    }
}

@media screen and (max-width: 1280px), (max-width: 1840px) {
    div.fleet-all-connected-chart.af-records-chart,
    div.fleet-all-soc-chart.af-records-chart,
    div.fleet-all-temperature-chart.af-records-chart,
    div.fleet-system-connected-chart.af-records-chart,
    div.fleet-system-soc-chart.af-records-chart,
    div.fleet-system-temperature-chart.af-records-chart,
    div.fleet-all-hw-flow-chart.af-records-chart,
    div.fleet-all-hw-median-chart.af-records-chart,
    div.fleet-all-hw-sensor-chart.af-records-chart,
    div.fleet-system-hw-flow-chart.af-records-chart,
    div.fleet-hw-median-chart.af-records-chart,
    div.fleet-hw-sensor-chart.af-records-chart,
    div.fleet-dispatched-chart.af-records-chart,
    div.fleet-energy-chart.af-records-chart,
    div.fleet-demand-response-chart.af-records-chart,
    div.fleet-performance .fleet-demand-response-chart.af-records-chart,
    div.af-records-chart {
        height: 45vh;
    }

    .fleet-demand-response-chart.af-records-chart.not-af-view{
        height: 90vh !important;
    }

    div.left-graphs-wrapper.af-view {
        height: 100%;
    }

    div.left-graphs-wrapper.af-view div.fleet-dispatched-chart.af-records-chart,
    div.left-graphs-wrapper.af-view div.fleet-energy-chart.af-records-chart {
        height: 90vh;
    }
}

@media (min-width: 320px) and (max-width: 668px) {
    div.af-records-chart {
        width: 99%;
        margin: 2px auto;
    }
}


@media screen and (max-width: 659px), (min-width: 1440px) and (max-width: 1560px) {

    .line-graph-header {
        flex-flow: wrap;
        align-items: center;
    }

    .battery-systems-dropdown-con{
        
        display: flex;
        justify-content: flex-end;
    }

    .selected-af {
        padding: 0 5px;
        margin: 4px 2px 3px 0px;
    }

    .battery-systems,
    .hw-tanks {
        margin: 7px 5px 0 0;
    }
}


@media screen and (max-width: 600px) {

    .fleet-chart-tabs.select-view .fleet-chart-view {
        border: 1px solid rgba(187, 187, 187, 0.33);
        border-radius: 3px;
    }

    .fleet-chart-tabs>span {
        font-size: 0.65rem;
    }

    .fleet-chart-tabs.select-view>span {
        padding: 4px 6px;
    }
}

@media screen and (max-width: 455px) {
    .selected-af {
        display: none;
    }

    .line-graph-header {
        padding: 4px;
    }
}