/* Copyright Levelise Ltd 2019-2024 */

.status-wrapper {
    margin: 2.5px 2.5px;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 100%;
    background: white;
    border-radius: 3px;
    border: 1px solid rgba(187, 187, 187, 0.33);
    overflow: hidden;
}

.status-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-overflow-y: hidden;
    -moz-overflow-y: hidden;
    -ms-overflow-style: none;
}

.status-wrapper.installer {
    margin-top: 2.5px;
}

ul.status {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: 0;
    padding: 0;
    flex-grow: 1;
}
.status-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.status-body::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.status-body::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
} 

.fleet-table-title {
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
    padding: 5px;
    min-height: fit-content;
    align-items: center;
}

div.fleet-table-title.status {
    border-bottom: none;
    padding-bottom: 4px;
    position: relative;
}

div.fleet-table-title.status #download-btn {
    right: 5.5px;
    top: 4.5px;
}

.status-selects-wrapper {
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0.5px auto 0.5px;
    height: 143px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.status-select-options {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.55rem;
    display: flex;
    justify-content: flex-start;
    background: rgb(245, 245, 245);
    width: 100%;
}

.status-select-options li {
    background: rgb(245, 245, 245);
    padding: 3px 0;
    width: -webkit-fill-available;
    cursor: pointer;
}

.severity-options li {
    padding: 5px 0 5px 0;
    margin-bottom: 3px;
}

.status-select-options li#all-status {
    width: 120px;
}

.status-select-options li#oaas-status,
.status-select-options li#non-oaas-status {
    width: 200px;
}

.status-select-options li#highest-severity.selected-status-option {
    background: white;
    /* border-bottom: white; */
    border-right: 0.5px solid rgba(187, 187, 187, 0.33);
    border-top: 1px solid rgba(187, 187, 187, 0.33);
    border-top-right-radius: 3px;
}

.status-select-options li#all-status.selected-status-option {
    background: white;
    border-bottom: white;
    border-right: 0.5px solid rgba(187, 187, 187, 0.33);
    border-top: 1px solid rgba(187, 187, 187, 0.33);
    border-top-right-radius: 3px;
}

.status-select-options li#oaas-status.selected-status-option {
    background: white;
    border-bottom: white;
    border-right: 0.5px solid rgba(187, 187, 187, 0.33);
    border-left: 0.5px solid rgba(187, 187, 187, 0.33);
    border-top: 1px solid rgba(187, 187, 187, 0.33);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.status-select-options li#all-severity.selected-status-option {
    background: white;
    /* border-bottom: white; */
    border-left: 0.5px solid rgba(187, 187, 187, 0.33);
    border-top: 1px solid rgba(187, 187, 187, 0.33);
    border-top-left-radius: 3px;
}

.status-select-options li#non-oaas-status.selected-status-option {
    background: white;
    border-bottom: white;
    border-left: 0.5px solid rgba(187, 187, 187, 0.33);
    border-top: 1px solid rgba(187, 187, 187, 0.33);
    border-top-left-radius: 3px;
}

.status-select-options li.unselected-status-option {
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
}

ul.status li {
    padding: 4px 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 23.3px;
    max-height: 60px;
    border-bottom: 1px solid #e7e7e7;
    align-items: center;
}

#safari ul.status li {
    min-height: 23px;
}

ul.status li.li-item {
    cursor: pointer;
}

ul.status > li.li-item {
    font-weight: bold;
}

ul.status li:nth-child(even) {
    background: #f3f3f3;
}

ul.status>ul.status-scroll>li:nth-child(odd) {
    background: #f3f3f3;
}

ul.status>ul.status-scroll>li:nth-child(even) {
    background: white;
}

ul.status li.sub-list,
ul.status ul.status-scroll li.sub-list {
    padding-left: 25px;
}

ul.status li:last-of-type {
    border-bottom: None;
}

ul.status li.sub-list span:first-of-type,
ul.status ul.status-scroll li.sub-list span:first-of-type {
    font-size: 0.72rem;
    text-align: start;
}

ul.status li.connection-status {
    padding-left: 25px;
    background: white;
}

ul.status li.li-item:hover,
ul.status li.li-item:focus,
ul.status li.sub-list.li-item:hover,
ul.status li.sub-list.li-item:focus {
    background: rgba(16, 154, 192, 0.2);
}

ul.status ul.status-scroll li.other-fault li:hover,
ul.status ul.status-scroll li.other-fault li:focus {
    background: inherit;
}

ul.status li.connection-status-separator {
    pointer-events: none;
}

ul.status ul.status-scroll li.other-fault {
    cursor: default;
}

ul.status li.selected-status,
ul.status ul.status-scroll li.selected-status {
    background: rgba(130,202,198, 1);
    border-left: 0.1px solid #e7e7e7;
    border-right: 0.1px solid #e7e7e7;
}

ul.status-scroll {
    height: 260px;
    width: 100%;
    padding: 0;
    overflow-x: hidden;
    -webkit-overflow-y: hidden;
    -moz-overflow-y: hidden;
    -ms-overflow-style: none;
}

#safari ul.status-scroll {
    height: 279px;
}

.css-1rvjjv6-menu ::-webkit-scrollbar-track,
ul.status-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.css-1rvjjv6-menu ::-webkit-scrollbar,
ul.status-scroll::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.css-1rvjjv6-menu ::-webkit-scrollbar-thumb,
ul.status-scroll::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgb(208, 208, 208);
}