.customers-list-item {
    display: flex;
    flex-direction: row !important;
}
.customer-list-item-div {
    display: flex;
    flex-grow: 1;
    height: unset !important;
    padding: 0px 0 !important;
}

.go-to-facility-button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
}