/* Copyright Levelise Ltd 2020-2021 */
.configure-route {
    margin: 0 auto;
    max-width: 600px;
    min-height: 100vh;
    padding: 10px 5px;
}

.specification-form-container {
    margin-top: 40px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
}

.specification-form-header {
    background: #ebebeb;
    padding: 6px 15px 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.specification-form-title {
    font-size: 0.8rem;
    font-weight: 600;
}

.specification-form {
    padding: 15px 0 10px;
    border-top: 1.2px solid #ebebeb;
}

.required-info {
    font-size: 0.8rem;
}

.form-type,
.facility-name,
.facility-password,
.facility-assignment > div,
.facility-specification > div {
    font-size: 0.75rem;
    display: flex;
    padding: 6px 0;
}

.SpecLabel {
    vertical-align: baseline;
    margin-left: 0px;
    margin-right: 10px;
    color: #828282;;
}

.SpecInput {
    border: 1px solid grey;
    border-radius: 3px;
    padding: 4.5px 4px 4.5px 10px;
    font-size: 0.75rem;
    outline: none;
}

.SpecInput[type=radio] {
    margin: 0 3px;
}

/* .metering-types, */
.dr-dc-allowed,
.edit-options,
.connection-types {
    padding-bottom: 2px;
    margin-right: 10px;
    text-align: left;
}

.metering-types {
    text-align: left;
}


.metering-types .metering-col2 {
    padding-left: 8px;
}

.metering-types .metering-col1 > div:not(:last-child),
.metering-types .metering-col2 > div:not(:last-child) {
    padding-bottom: 2px;
}

.invert-pv.SpecInput[type=checkbox],
.invert-grid.SpecInput[type=checkbox],
.invert-dru-inverter.SpecInput[type=checkbox],
.invert-dru-grid.SpecInput[type=checkbox],
.invert-dru-hw.SpecInput[type=checkbox],
.edit-option.SpecInput[type=checkbox],
.has-phase-metering.SpecInput[type=checkbox],
.has-grid-meter.SpecInput[type=checkbox],
.dr-allowed.SpecInput[type=checkbox],
.data-collection-allowed.SpecInput[type=checkbox],
.connection-type.SpecInput[type=checkbox] {
    height: 9px;
    margin: 0 0.5px;
}

.invert-pv-label.SpecLabel,
.invert-grid-label.SpecLabel,
.invert-dru-inverter-label.SpecLabel,
.invert-dru-grid-label.SpecLabel,
.invert-dru-hw-label.SpecLabel,
.edit-option-label.SpecLabel,
.phase-metering-label.SpecLabel,
.grid-meter-label.SpecLabel,
.dr-allowed-label.SpecLabel,
.data-collection-allowed-label.SpecLabel,
.connection-type-label.SpecLabel {
    font-size: 0.7rem;
    margin-right: 8px;
}

.SpecLabel.disabled {
    color: rgb(189, 189, 189);
}

.form-left {
    width: 40%;
    align-self: center;
    text-align: -webkit-right;
    text-align: right;
    padding: 0 10px;
    font-weight: 600;
    color: rgb(85, 85, 85);
}


.facility-option.form-left,
.settlement.form-left,
.dno-option.form-left {
    padding-top: 16px;
}

.metering-label.form-left {
    align-self: flex-start;
}

.form-right {
    width: 300px;
}

.form-right#postCode,
.form-right#lineLossFactor,
.form-right#importLimit,
.form-right#exportLimit,
.form-right#nominalPv {
    width: 180px;
}

.form-type .form-right {
    display: flex;
}

.facility-password .form-left {
    padding-bottom: 31px;
}

.facility-password .form-right #newPassword {
    margin-bottom: 6px;
}

.facility-password .form-right #newPassword,
.facility-password .form-right #confirmPassword {
    width: 300px;
}

.metering-types {
    display: flex;
    flex-direction: row
}

.required {
    color: rgb(202, 0, 0);
    font-weight: 600;
}

.required-field {
    border: 1px solid rgb(202, 0, 0);
}

.submit-spec{
    position: relative;
    padding: 10px 45px;
    text-align: right;
}

button.configure-btn {
    font-size: 0.75rem;
    margin: 10px 2px 0px;
    height: 25px;
    width: 82px;
    border: none;
    border-radius: 2px;
    color: white;
    outline: none;
    cursor: pointer;
}

button.configure-btn.submit {
    background:  #028eaf;
}

button.configure-btn.reset {
    background: rgb(168, 168, 168);
}

button.configure-btn.reset:disabled {
    background: rgba(168, 168, 168, .6);
    cursor:default;
}

button.configure-btn.submit:hover {
    background: #028eaf;
    transition: all 0.5s;
}

button.configure-btn.submit:disabled {
    background: rgba(2, 142, 175, .6);
    cursor:default;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.message-container {
    text-align: left;
    position: absolute;
    bottom: 5px;
    left: 15px;
}

.agg-facilities > .form-left {
    margin-bottom: 22px;
}

.facility-assignment-note {
    width: 300px;
    margin: 1.5px 0 0 1.5px;
    color: rgb(0, 0, 0);
    letter-spacing: 0.2px;
    font-size: 0.7rem;
    text-align: left;
}

.error-message-spec {
    margin: 0;
    color: rgb(179, 0, 0);
    font-weight: 500;
    font-size: 0.75rem;
}

.info-message-spec {
    margin: 0;
    color: rgb(2, 135, 172);
    font-weight: 500;
    font-size: 0.75rem;
}

.invert-selects-container {
    width: unset;
}

/* Mozilla styles */
@-moz-document url-prefix('') {
    .invert-pv-label.SpecLabel,
    .invert-grid-label.SpecLabel,
    .invert-dru-inverter-label.SpecLabel,
    .invert-dru-grid-label.SpecLabel,
    .invert-dru-hw-label.SpecLabel,
    .edit-option-label.SpecLabel,
    .phase-metering-label.SpecLabel,
    .grid-meter-label.SpecLabel,
        .dr-allowed-label.SpecLabel,
        .data-collection-allowed-label.SpecLabel,
    .connection-type-label.SpecLabel {
        vertical-align: top;
        padding-left: 3px;
    }

    .invert-pv.SpecInput[type=checkbox],
    .invert-grid.SpecInput[type=checkbox],
    .invert-dru-inverter.SpecInput[type=checkbox],
    .invert-dru-grid.SpecInput[type=checkbox],
    .invert-dru-hw.SpecInput[type=checkbox],
    .edit-option.SpecInput[type=checkbox],
    .has-phase-metering.SpecInput[type=checkbox],
    .has-grid-meter.SpecInput[type=checkbox],
    .dr-allowed.SpecInput[type=checkbox],
    .data-collection-allowed.SpecInput[type=checkbox],
    .connection-type.SpecInput[type=checkbox] {
        -moz-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -o-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@media all and (max-width: 580px){
    .configure-route {
        margin: 5px auto;
        min-height: 98vh;
    }

    .specification-form-container {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        width: 340px;
    }
    .specification-form {
        padding: 10px 0;
        margin: auto;
        align-items: center;
    }

    .facility-name,
    .facility-password,
    .facility-assignment > div,
    .facility-specification > div {
      flex-direction: column;
    }

    .form-type,
    .facility-name,
    .facility-password,
    .facility-assignment,
    .facility-specification {
        width: 95%;
    }

    .form-left {
        text-align: -webkit-left;
        text-align: left;
        width: 100%;
        margin-left: 0;
    }

    .form-right {
        margin: 0 5px 2px 8px;
        width: 318px;
    }

    .facility-password .form-left {
        padding-bottom: 2px;
    }


    .form-type .form-left {
        width: 100px;
        padding-right: 0;
    }

    .form-type .form-right {
        margin-left: 0;
    }

    .facility-password .form-right #newPassword,
    .facility-password .form-right #confirmPassword {
        width: 100%;
    }
    
    .form-right#postCode,
    .form-right#lineLossFactor,
    .form-right#importLimit,
    .form-right#exportLimit,
    .form-right#nominalPv {
        width: 200px;
    }

    .facility-assignment-note,
    .agg-facilities > .form-left,
    .form-right {
        margin: 0 2px 2px 10px;
    }
    
    .submit-spec {
        position: relative;
        padding: 10px 10px;
        text-align: right; 
    }

    .message-container {
        text-align: left;
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    
    .facility-option.form-left,
    .settlement.form-left,
    .dno-option.form-left {
        padding-top: 0;
    }

    /* .metering-types, */
    .dr-dc-allowed,
    .edit-options,
    .phase-metering,
    .connection-types {
        padding-top: 3px;
        margin-right: 10px;
        margin-left: 10px;
        text-align: left;
    }

    .facility-option.form-left,
    .settlement.form-left,
    .dno-option.form-left,
    .form-left {
        padding-bottom: 2px;
    }
}

@media all and (max-width: 380px){
    .specification-form-container {
        width: 320px;
    }

    .form-type,
    .facility-name,
    .facility-password,
    .facility-assignment,
    .facility-specification {
        width: 100%;
    }

    .form-right {
        margin: 0 5px 2px 8px;
        width: 300px;
    }
}

@media all and (max-width: 340px){
    .specification-form-container {
        width: 310px;
    }

    .form-type,
    .facility-name,
    .facility-password,
    .facility-assignment,
    .facility-specification {
        width: 100%;
        margin: auto;
    }

    .facility-password .form-right #newPassword,
    .facility-password .form-right #confirmPassword {
        width: 100%;
    }


    .form-right {
        margin: 0 5px 2px 8px;
        width: 280px;
    }

    .dr-dc-allowed {
        margin-right: 0px;
    }
}

@media screen and (max-width: 430px) {
    .invert-selects-container {
        width: 170px;
    }
}
