/* Copyright Levelise Ltd 2022-2024 */

fieldset {
    min-width: 0;
    padding: 0;
    margin: 15px 0 10px;
    border: 0;
}

fieldset .Input {
    width: 90%;
}

#mfa-enrol {
    margin: 10px 20px 15px;;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: self-start;
    font-family: 'Inter-Medium';
    color:  rgb(63, 63, 63);
    border: none;
}

#mfa-enrol-title {
    padding: 0;
    margin: 0;
    font-family: 'Inter-Bold';
    margin: 10px 0;
    font-size: 18px;
    text-align: start;
}

.mfa-enrol-step {
    padding: 0;
    margin: 5px 5px 5px 20px;
    font-weight: 500;
    font-size: 13.5px;
    text-align: start;
}

#mfa-qr{
    margin: 0px 0px 0px 30px;
    width: 200px;
    height: 200px;
}

#mfa-qr-show {
    margin: 5px 5px 5px 35px;
    width: 190px;
    height: 190px;
    border: 1px solid rgb(240, 240, 240);
    background: rgb(247, 247, 247);
    display: flex;
    justify-content: center;
    align-items: center;
}

#mfa-qr-show-text {
    color: rgb(28, 132, 160);;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Inter-Bold';
}

#mfa-code-wrapper {
    font-weight: 500;
    font-size: 13px;
    margin: 0px 0px 0px 35px;
    color:  rgb(63, 63, 63);
}

#mfa-code1-input,
#mfa-code2-input {
    height: 25px;
    width: 150px;
    font-size: 13px;
    margin: 2px 0 6px 0;
}

.mfa-enrol-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 5px 0;
}

.change-password-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;  
    padding: 5px 0px 5px 10px;
}

.mfa-enrol-btn {
    width: 100px;
    margin-top: 10px;
    margin-left: 5px;
    height: 25px;
    border-width: 1px;
    font-family: 'Inter-Light';
}

.mfa-enrol-btn.activate {
    background-color: rgb(28, 132, 160, 0.85);
    border: 1px solid rgb(28, 132, 160, 0.85);
    color: white;
    cursor: pointer;
}

.mfa-enrol-btn.cancel {
    background-color: rgb(94, 94, 94, 0.85);
    border: 1px solid rgb(94, 94, 94, 0.85);
    color: white;
    cursor: pointer;
}

#mfa-activated {
    padding: 10px 35px;
}

#mfa-activation-message,
#mfa-activation-info {
    text-align: start;
    font-size: 15px;
    padding: 5px 0 0 0;
    margin: 0 0;
}

#mfa-activation-message {
    font-size: 16px;
    font-weight: 500;
    color: #76b82a;
}

#mfa-activation-info {
    font-size: 13px;
    padding-left: 22px;
    color:  rgb(63, 63, 63);
}

.mfa-enrol-btn.activate:hover,
.mfa-enrol-btn.activate:focus {
    background-color: rgb(28, 132, 160);
    border: 1px solid rgb(28, 132, 160);
}

.mfa-enrol-btn.cancel:hover,
.mfa-enrol-btn.cancel:focus {
    background-color: rgb(94, 94, 94);
    border: 1px solid rgb(94, 94, 94);
}

.password-alert,
.mfa-alert {
    font-size: 15px;
    margin: 10px 0px 0px 35px;
    color: crimson;
}

#setup-mfa {
    margin-right: 5px;
}

#setup-mfa > span {
    color: rgb(28, 132, 160);
    cursor: pointer;
    margin: 15px 0;
    font-size: 13.5px;
    text-align: center;
    font-family: 'Inter-Medium';
}

@media all and (max-width: 550px) {
    #mfa-enrol {
        margin: 0px 10px;
    }
}

@media all and (max-width: 470px) {
     #mfa-enrol-title {
        margin: 10px 0;
    }
    
    .mfa-enrol-step {
        margin: 5px 5px 5px 5px;
        font-size: 12px;
    }
    
    #mfa-qr{
        margin: 0px 0px 0px 10px;
    }
    
    #mfa-qr-show {
        margin: 5px 5px 5px 20px;
    }

    #mfa-code-wrapper {
        margin: 0px 0px 0px 20px;
    }
}
