/* Copyright Levelise Ltd 2020 - 2025 */

.timeframes {
    display: flex;
    height: 38px;
    flex-direction: row;
    justify-content: center; 
    padding-right: 10px;
    order: 1;
    border-bottom: 1px solid rgb(187, 187, 187);
}

.timeframes-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

select.timeframes-options {
    display: none;
    outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	 -o-appearance: none;
		appearance: none;
}

select.timeframes-options option {
    font-size: 0.7rem;
    outline: none;
    padding: 0;
    margin: 0;
}

.timeframes-list li {
    margin: 6px 0;
    padding: 5px 8px 5px 6px;
    font-size: 0.7rem;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Inter-Bold';
    font-weight: bold;
    font-style: normal;
}   

.timeframes-list li:not(:first-child) { 
    border-left: 1.25px solid rgb(219, 219, 219, 0.5);
}

.timeframes-list li:hover,
.timeframes-list li:focus {
    color: rgb(2, 142, 175);
}

li.selected-timeframe {
    color: rgb(2, 142, 175);
    background: white;
}

.range-picker {
    display: flex;
    flex-direction: row;
}

.date-picker-input {
    width: 100px;
    text-align: center;
    margin: 6px 2px;
    border-radius: 1px;
    border: 1px solid rgba(187, 187, 187, 0.33);
    padding: 4px 3px;
    font-size: 0.7rem;
    font-family: 'Inter-Medium';
}

.react-datepicker__navigation--next {
    width: 20px;
    height: 20px;
}

.react-datepicker__navigation--previous {
    width: 20px;
    height: 20px;
}

.react-datepicker {
    font-size: 0.75rem !important;
    font-family: 'Inter-Light';
}
  
.react-datepicker__current-month {
    font-size: 0.8rem !important;
    font-family: 'Inter-Medium';
    text-align: center;
}
  
.react-datepicker__header {
    padding-top: 5px !important;
    text-align: center !important;
}
  
.react-datepicker__navigation {
    top: 5px !important;
}
  
.react-datepicker__day-name, .react-datepicker__day {
    margin: 0rem !important;
}

@media all and (max-width: 1360px) {
    .timeframes-list li {
        margin: 3px 0;
        padding: 2px 6px;
        font-size: 0.65rem;
    }

    .date-picker-input {
        width: 90px;
        margin: 2px 2px;
        padding: 3px 10px;
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 1300px) {
    .timeframes {
        /* border-top: 1px solid rgba(187, 187, 187, 0.33); */
        width: 100%;
        order: 2;
        padding-left: 4px;
        padding-top: 2px;
        height: unset;
    }
}

@media all and (max-width: 950px) {
    .timeframes-list li { 
        font-size: 0.6rem;
        padding: 2px 3px;
    }
}

@media all and (max-width: 820px) { 
    .timeframes {
        width: 100%;
        justify-content: flex-end;
    }

    .date-picker-input {
        width: 120px;
    }

    select.timeframes-options {
        display: block;
        display: initial;
        font-weight: 600;
        margin: 2px 2px;
        padding: 3px 15px;
        width: 100%;
        font-size: 0.7rem;
        color: #60666d;
        border: 1px solid rgba(187, 187, 187, 0.33);
    }

    #select-wrapper {
        position: relative;	
        width: 130px;
        display: flex;
    }

    #select-wrapper:after {
        content: "\25bc"; 
        font-size: 0.6rem;
        position: absolute;
        top: 7px;
        right: 5px;
        color: rgb(187, 187, 187);
        pointer-events: none;
    }

    #safari #select-wrapper:after {
        content: none;
    }

    select::-ms-expand {
        display: none;
    }

    select.timeframes-options option:hover,
    select.timeframes-options option:focus {
        background: rgb(11, 126, 158, 0.8)
    }


    ul.timeframes-list {
        display: none;
    }
}


@media all and (max-width: 425px) { 
    .date-picker-input {
        width: 95px;
    } 
}
