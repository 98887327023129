/* Copyright Levelise Ltd 2024 */
ul.cot-customer-list {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    list-style: none;
    text-align: left;
    height: calc(92vh - 150px);
    overflow-x: hidden;
    -webkit-overflow-y: hidden;
    -moz-overflow-y: hidden;
    -ms-overflow-style: none;
}

ul.cot-customer-list li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.72rem;
    border-bottom: 1px solid #e7e7e7;
    overflow: hidden;
    min-height: 25px;
}

ul.cot-customer-list li div {
    display: flex;
    justify-content: space-between;
    padding: 4px 6px;
    height: 20px;
}

ul.cot-customer-list li div.af-name {
    cursor: pointer;
}

ul.cot-customer-list li div.af-name.last-slot {
    padding: 3.5px 6px;
}

ul.cot-customer-list > li:nth-child(even) {
    background: #f3f3f3;
}

ul.dru-list-cot {
    height: 46vh
}

ul.dru-list-cot {
    height: calc(92vh - 165px);
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    overflow-x: auto;
    -webkit-overflow-y: auto;
    -ms-overflow-style: none;
    top: 90px;
    bottom: 0;
}

ul.dru-list-cot li {
    padding: 4px 6px;
    font-size: 0.75rem;
    border-bottom: 1px solid #e7e7e7;
    overflow: hidden;
}

ul.dru-list-cot li.dru-item {
    cursor: pointer;
}

ul.dru-list-cot li:last-of-type {
    border-bottom: none;
}

ul.dru-list-cot li.dru-item:hover,
ul.dru-list-cot li.dru-item:focus,
ul.dru-list-cot li.dru-item:nth-child(even):hover,
ul.dru-list-cot li.dru-item:nth-child(even):focus {
    background: rgba(16, 154, 192, 0.2);
}

ul.dru-list-cot li:nth-child(even) {
    background: #f3f3f3;
}

ul.dru-list-cot li.selected, .selected-customer {
    background-color: rgba(130,202,198, 1) !important;
}

ul.dru-list-cot::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
  
ul.dru-list-cot::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}
  
ul.dru-list-cot::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
} 