/* Copyright Levelise Ltd 2019 */
.App {
    text-align: center;
}

#loading-route {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

h2 {
    margin: 0;
    font-size: 0.8rem;
    text-align: left;
    padding-left: 5px;
}