/* Copyright Levelise Ltd 2024 */
.orders-page {
    height: calc(100vh - 43px);
    margin-top: 42px;
    display: grid;
    grid-template-columns: 2fr 3fr 7fr;
}

.container-border-style {
    border: 1px solid rgba(187, 187, 187, 0.33);
    border-radius: 3px;
}

.child-containers {
    margin: 2.5px;
    min-height: 0;
}

.installation-companies-list {
    height: calc(100vh - 165px);
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    overflow-x: hidden;
    -webkit-overflow-y: hidden;
    -moz-overflow-y: hidden;
    -ms-overflow-style: none;
}

ul.installation-companies-list li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.72rem;
    border-bottom: 1px solid #e7e7e7;
    overflow: hidden;
    min-height: 25px;
    cursor: pointer;
    padding-left: 5px;
}

ul.installation-companies-list li span{
    font-size: 0.7rem;
    font-family: 'Inter-Medium';
}

ul.installation-companies-list li div.af-name.last-slot {
    padding: 3.5px 6px;
}

ul.installation-companies-list > li:nth-child(even) {
    background: #f3f3f3;
}

ul.installation-companies-list li:hover {
    background: rgba(130, 202, 198, .5);
}
ul.installation-companies-list li.selected {
    background: rgba(130, 202, 198, 1);
}

ul.installation-companies-list::-webkit-scrollbar-track, ul.orders-list-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
  
ul.installation-companies-list::-webkit-scrollbar, ul.orders-list-container::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}
  
ul.installation-companies-list::-webkit-scrollbar-thumb, ul.orders-list-container::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
} 

.orders-list-container {
    margin: 0;
    list-style: none;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    padding-bottom: 20px;
    overflow-y: auto
}

.orders-list-item{
    display: flex;
    flex-direction: column;
    border-bottom:0.5px solid #ddd;
}

.orders-detail-items span {
    font-size: 0.7rem;
}

ul.orders-list-container > .orders-list-item:nth-child(even) {
    background: #f3f3f3;
}

.orders-detail-container {
    margin: 0;
    list-style: none;
    overflow-x: hidden;
    padding-bottom: 5px;
}

.orders-detail-items {
    display: flex;
    justify-content: space-between;
    border-bottom:0.5px solid #ddd;
}

.orders-detail-items:last-child {
    border-bottom:0px solid #ddd !important;
}

.sub-list-in-list-item {
    display: flex;
    flex-direction: column;
}

.discounts-container {
    padding: 0;
}
.discount-list-item {
    padding: 0;
    margin: 0;
    margin-left: 20px;
}

.discount-list-item li {
    display: flex;
    justify-content: space-between;
    background:transparent;
    border-bottom:0.5px solid #ddd;
    margin-left: 0;
}

.discount-list-item li:last-child {
    border-bottom: 0px solid #ddd;
}

.discounts-container .discount-list-item:nth-child(even) {
    background: #f3f3f3;
}

.discounts-container .discount-list-item:nth-child(odd) {
    background: #fff;
}

@media screen AND (max-width: 1080px ) {
    .orders-page {
        grid-template-columns: 3fr 9fr;
        grid-template-rows: calc(100vh - 43px) calc(100vh - 43px);
    }

    .orders-column {
        grid-column: span 2;
    }
}

@media screen AND (max-width: 670px ) {
    .orders-page {
        grid-template-columns: 1fr;
        grid-template-rows: calc(100vh - 43px) calc(100vh - 43px) calc(100vh - 43px);
        
    }

    .orders-column {
        grid-column: 1;
    }
}