/* Copyright Levelise Ltd 2019-2023 */
.live-status-body {
    padding: 0 5px;
    position: relative;
}

div.live-status h2 {
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
    padding: 5px;
}

ul.status-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 5px;
    right: 5px;
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
}

ul.status-list li {
    margin: 0 1.5px;
    padding: 3px 3px;
    border-radius: 2px;
    font-size: 0.5rem;
    cursor: default;
}

li.good{
    background: rgb(100, 182, 100);
    color: white;
}

li.reversed{
    background: rgb(232, 96, 53);
    color: white;
}

li.bad{
    background: rgb(168, 25, 22);
    color: white;
}

li.unknown{
    background: rgb(221, 221, 221);
    color: black;
}

/* Tooltip */
.status-tooltip {
    position: relative;
    display: inline-block;
}

.status-tooltip .tooltiptext {
    font-size: 0.65rem;
    visibility: hidden;
    width: 130px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    padding: 4px 0;
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 50%;
    margin-left: -60px;
}

.status-tooltip:hover .tooltiptext {
    visibility: visible;
}

.status-tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -3px;
    border-width: 3px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

#faults {
    padding: 0;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 0 5px 5px;
    min-height: 30px;
}

.power-flow-diagram.dc {
    margin-top: 23px;
}

#faults .fault {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    width: fit-content;
}

#faults .fault .fault-icon-wrapper {
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#faults .fault ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    text-align: start;
    padding: 2px 5px;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start; 
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.severity-level {
   margin-bottom: 4px;
   padding-bottom: 1px;
   width: 100%;
   /* font-weight: 600; */
   border-bottom: 0.1px solid white;
}

.svg-time {
    transform: translate(5px,370px);
}

@media  screen and (min-width: 1441px) {
    #faults {
       margin-top: -30px;
    }
}

@media screen and (max-width: 1440px) {
    .power-flow-diagram.dc {
        margin-top: 20px;
    }

    .svg-time {
        transform: translate(5px,385px);
    }

    .fault {
        margin-top: -12px;
    }
}
  
@media (min-width: 320px) and (max-width: 480px) {
    ul.status-list {
        flex-wrap: wrap;
    }
      
    ul.status-list li {
        max-width: 50%;
        margin-bottom: 0.5%;
    } 
}

@media screen and (max-width: 426px) {
    div.live-status-header {
        flex-direction: column;
        align-items: start;
    }

    ul.status-list {
        padding-top: 2.5px;
    }

    .svg-time {
        transform: translate(5px,390px);
    }

    .power-flow-diagram.dc {
        margin-top: 40px;
    }

    .fault {
        margin-top: 0px;
    }
}
