/* Copyright Levelise Ltd 2024 - 2025 */
.fleet-customer-container {
    margin: 45px auto 5px;
    padding-left: 2.5px;
    padding-right: 2.5px;
    width: 100%;
    height: calc(100vh - 55px);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: minmax(calc(100vh - 55px), auto);
    gap: 3px;

    overflow-y: auto;
}

.container-border-style {
    border: 1px solid rgba(187, 187, 187, 0.33);
    border-radius: 3px;
}

.credits-reports-container {
    grid-column: 2/8;
    grid-row: 1;
    display: grid;
    grid-template-rows: 2fr 1fr;
    row-gap: 5px;
    height: 100%
}

.consent-status-container {
    grid-column: 8/10;
    grid-row: 1;
    height: 100%
}

.tariff-status-container {
    grid-column: 10/12;
    grid-row: 1;
    height: 100%
}

.filtered-customers-id-container {
    grid-column: 12;
    grid-row: 1;
    height: 100%
}

.credits-hidden {
    grid-template-rows: 1fr;
}

.fleet-customers-search-container {
    grid-column: 1/2;
    grid-row: 1;
    overflow: hidden;
    min-width: 230px;
}
.credits-container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 24px);
}


.table-container {
    height: calc(100% - 50px);
    flex-grow: 1;
}

.scrollable-table-credits {
    height: 100%;
    padding-bottom: 60px;
    overflow-y: auto;
    overflow-x: auto;
    border: 1px solid #e7e7e7;
}

.scrollable-table-credits table thead tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
}

.scrollable-table-credits table tbody tr td {
    padding-top: 10px;
    padding-bottom: 5px;
}

.scrollable-table-credits th {
    font-size: 0.75rem;
    border-bottom: 0.1px solid rgba(187, 187, 187, 0.33);
}

.scrollable-table-credits td {
    border-bottom: 1px solid #e7e7e7;
}


.scrollable-table-credits::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

 .scrollable-table-credits::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}
.scrollable-table-credits::-webkit-scrollbar:horizontal {
    height: 5px;
    background-color: #F5F5F5;
}

.scrollable-table-credits::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}

.scrollable-table-tariffs {
    height: 100%;
}

.customers-credits-table td, .customers-credits-table th {
    padding-left: 5px;
}

.table-col-1 {
    width: 5%;
}

.table-col-2 {
    width: 15%;
}
.table-col-3{
    width: 40%;
}
.table-col-4 {
    width: 20%
}
.table-col-5 {
    width: 45%;
}

.table-col-6 {
    width: 6%;
}

.table-col-7 {
    width: 71%;
}

.table-col-8 {
    width: 25%;
}

.table-col-9 {
    width: 25%;
}

.fleet-customer-buttons-container {
    display: flex;
    padding: 10px;
    margin-left: 5px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.credits-container {
    flex-direction: column;
    justify-content: flex-start;
}

.upload-credits-button:hover {
    cursor: pointer;
}

.upload-credits-button-container {
    display: flex;
    margin-top: 5px;
    align-items: flex-end;
}

.fleet-customer-action-button {
    width: 150px;
    height: 40px;
    margin-left: 0;
    margin-right: 2px;
    margin-top: 5px;
}

.extension-text {
    font-size: 10px;
    align-self: flex-end;
}

.help-text {
    display: inline-block
}

.list-end-indicator {
    cursor: default !important;
    height: 1.22rem;
    border-bottom: 0.5px solid #f3f3f3;
}

@media screen and (max-width: 1480px) {
    .help-text {
        display: none
    }
}

@media screen and (max-width: 1280px) {
    .fleet-customers-search-container {
        grid-column: 1/4;
        grid-row: 1;
    }
    .credits-reports-container {
        grid-column: 4/13;
        grid-row: 1;
    }
    
    .consent-status-container {
        grid-column: 1/6;
        grid-row: 2;
    }
    
    .tariff-status-container {
        grid-column: 6/11;
        grid-row: 2;
    }
    
    .filtered-customers-id-container {
        grid-column: 11/13;
        grid-row: 2;
    }
}

@media screen and (max-width: 1200px) {
    .table-container {
        height: calc(100% - 130px);
    }
}

@media screen and (max-width: 860px) {
    .credits-container {
        flex-direction: column;
    }
    .credits-container .fleet-customer-buttons-container {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 540px) {
    .fleet-customer-container {
        grid-template-columns: 1fr;

    }

    .fleet-customers-search-container {
        grid-column: 1/10;
        grid-row: 1;
    }
    .credits-reports-container {
        grid-column: 1/10;
        grid-row: 2;
    }
    
    .consent-status-container {
        grid-column: 1/10;
        grid-row: 3;
    }
    
    .tariff-status-container {
        grid-column: 1/10;
        grid-row: 4;
    }
    
    .filtered-customers-id-container {
        grid-column: 1/10;
        grid-row: 5;
    }

}