/* Copyright Levelise Ltd 2020 */


.preferenceTwo.specs-wrapper {
    width: 520px;
    padding: 2.5px;
}

.preferenceTwo.specs-wrapper div#top-right {
    width: 100%;
}

.preferenceTwo.specs-wrapper div#top-left {
    width: unset;
}

.preferenceTwo.specs-wrapper .pie-chart-wrapper div {
    width: 47%;
}


.preferenceTwo.specs-wrapper .tableContainer {
    width: 100%;
    margin: 1px 0;
}

.preferenceTwo.specs-wrapper div#top-left {
    margin: 0;
}

.preferenceTwo.specs-wrapper div.tableContainer {
    /* height: 395px; */
}

.preferenceTwo.specs-wrapper tbody.scrollContent {
    /* height: 368px; */
}

@media screen and (min-height: 800px) and (min-width: 1441px) {
    .preferenceFive.specs-wrapper ul.facility-specifications {
        height: calc(100vh - 532px);
        max-height: calc(1500px - 532px);
    }
    .preferenceTwo.specs-wrapper div.tableContainer {
        height: calc(100vh - 505px);
        max-height: calc(1500px - 505px);
    }
    .preferenceTwo.specs-wrapper tbody.scrollContent {
        height: calc(100vh - 558px);
        max-height: calc(1500px - 558px);
    }
}

@media screen and (max-width: 1441px) { 
    .preferenceTwo.specs-wrapper {
        padding: 2.5px 0 2.5px 2.5px;
    }
}

@media screen and (max-width: 1024px) { 
    .preferenceTwo.specs-wrapper {
        width: 100%;
        padding: 2.5px;
    }
}

@media (min-width: 660px) and (max-width: 1024px) {
    .preferenceTwo div#top-right {
        width: 100%;
        display: flex;
    }
    
    .preferenceTwo div#top-left {
        width: unset;
    }

    .preferenceTwo div.live-status {
        min-width: 64%;
    }

    .preferenceTwo .summary-chart { 
        margin: 2.5px 0 2.5px 2.5px;
        min-width: 35%;
        height: unset;
    }

    .preferenceTwo .pie-chart-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .preferenceTwo.specs-wrapper .pie-chart-wrapper div {
        width: 205px;
    }
}
