/* Copyright Levelise Ltd 2023 */
.auth-route {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-loader {
    width: 30px;
    height: 30px;
    border: 3.5px solid #afafaf;
    border-bottom-color: #1c84a0;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}