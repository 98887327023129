/* Copyright Levelise Ltd 2019-2024 */

.list-wrapper, 
.list-wrapper-extended{
    width: 250px;
    overflow: hidden;
    margin: 2.5px !important;
    background: white;
    border: 1px solid rgba(187, 187, 187, 0.33);
}

.dru-list-header {
    display: flex;
    justify-content: space-between;
}

#dru-search-field,
#facility-search-field {
    font-size: 0.7rem;
    max-width: 50%;
    border: none;
    background: none;
    padding: 2px 6px;
    outline: none;
    transition-property: border;
    transition-duration: 0.8s;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 2.5px;
    background: white;
}

#dru-search-field:focus,
#facility-search-field:focus {
    background: white;
}

ul.dru-list {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    list-style: none;
    text-align: left;
    height: 891px;
    overflow-y: scroll;
    overflow-x: auto;
    -webkit-overflow-y: auto;
    -ms-overflow-style: none;
    top: 90px;
    bottom: 0;
}

ul.dru-list-extended {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    list-style: none;
    text-align: left;
    height: 607px;
    overflow-y: scroll;
    overflow-x: auto;
    -webkit-overflow-y: auto;
    -ms-overflow-style: none;
    top: 90px;
    bottom: 0;
}

ul.dru-list li,
ul.dru-list-extended li {
    padding: 4px 6px;
    font-size: 0.75rem;
    border-bottom: 1px solid #e7e7e7;
    overflow: hidden;
}

ul.dru-list li.dru-item,
ul.dru-list-extended li.dru-item {
    cursor: pointer;
}

ul.dru-list li:last-of-type,
ul.dru-list-extended li:last-of-type {
    border-bottom: none;
}

ul.dru-list li.dru-item:hover,
ul.dru-list li.dru-item:focus,
ul.dru-list li.dru-item:nth-child(even):hover,
ul.dru-list li.dru-item:nth-child(even):focus,
ul.facilities li.facility-item:hover,
ul.facilities li.facility-item:focus,
ul.facilities li.facility-item:nth-child(even):hover,
ul.facilities li.facility-item:nth-child(even):focus,
ul.dru-list-extended li.dru-item:hover,
ul.dru-list-extended li.dru-item:focus,
ul.dru-list-extended li.dru-item:nth-child(even):hover,
ul.dru-list-extended li.dru-item:nth-child(even):focus,
ul.facilities-extended li.facility-item:hover,
ul.facilities-extended li.facility-item:focus,
ul.facilities-extended li.facility-item:nth-child(even):hover,
ul.facilities-extended li.facility-item:nth-child(even):focus {
    background: rgba(16, 154, 192, 0.2);
}

ul.dru-list li:nth-child(even), 
ul.dru-list-extended li:nth-child(even) {
    background: #f3f3f3;
}

ul.dru-list::-webkit-scrollbar-track,
ul.dru-list-extended::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
  
ul.dru-list::-webkit-scrollbar,
ul.dru-list-extended::-webkit-scrollbar{
    width: 5px;
    background-color: #F5F5F5;
}
  
ul.dru-list::-webkit-scrollbar-thumb,
ul.dru-list-extended::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
} 
  