/* Copyright Levelise Ltd 2024 */

#add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: none;
    cursor: pointer;
    right: 0px;
    top: 0px;
    min-width: 18px;
    height: 18px;
}

#add-btn.delete {
    width: 18px;
    height: 18px;
    background: rgb(171, 46, 46);;
    color: white;
    border-radius: 2px;
}
