/* Copyright Levelise Ltd 2023-2024 */

.container{
    margin: 40px auto 5px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: calc(100vh - 50px);
}

.containt-container {
    height: inherit;
    display: grid;
    grid-template-rows: 2fr 1fr;
}

.containt-container.rightSide {
    height: inherit;
    display: grid;
    grid-template-rows: 1fr 7fr;
}

.containt-container.middle {
    height: inherit;
    display: grid;
    grid-template-rows: 1fr;
}

.tab-container-customer {
    margin: 4px 2.5px 0px;
    border-radius: 3px;
    overflow: hidden;
}

.left-side-container {
    height: 100%;
    border: 1px solid #ddd;
}

.customer-credits-container {
    height: 100%;
    border: 1px solid #ddd;
}

.scrollableTable {
    height: 96%;
    padding-bottom: 60px;
    overflow-y: auto;
    overflow-x: auto;
}

.scrollableTable table thead tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
}

.scrollableTable table tbody tr td {
    padding-top: 10px;
    padding-bottom: 5px;
}

.customer-info-container {
    margin: 0;
    list-style: none;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    padding-bottom: 20px;
}

.customer-info-container li{
    display: flex;
    justify-content: space-between;
}

.listItemWithSubList {
    flex-direction: column;
    justify-content: flex-start;
}

.listItemWithSubList-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.listItemWithSubList-listContainer {
    padding-left: 20px;
}

.listItemWithSubList-listContainer>li:last-child {
    border-bottom: 0px;
}

.collapsable-list-evidence  {
    padding-left: 0px;
}
.collapsable-list-evidence li {
    padding-left: 20px;
}

.listItemWithSubList-listContainer li {
    padding-left: 0;
    border-bottom: 1px solid #ddd;
}

.collapsable-list-evidence>li:nth-child(even) {
    background: #f3f3f3;
}
.customer-info-container>li:nth-child(even) {
    background: #f3f3f3;
}

.customer-info-container.customer-users-container li{
    display: block;
    padding-top: 2.5px;
    padding-bottom: 5px;
}

.customer-info-container.customer-facility-container>ul:nth-child(even) {
    background: #f3f3f3;
}

.customer-info-container.customer-facility-container ul{
    padding: 0;
}
.customer-info-container.customer-facility-container ul li{
    background:transparent;
    border-bottom: 0.5px solid #ddd;
}

.customer-info-container.customer-facility-container ul>li:last-child {
    border-bottom: 0px;
}

.customer-info-container::-webkit-scrollbar-track,
.scrollableTable::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.customer-info-container::-webkit-scrollbar, .scrollableTable::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}
.scrollableTable::-webkit-scrollbar:horizontal {
    height: 5px;
    background-color: #F5F5F5;
}

.customer-info-container::-webkit-scrollbar-thumb, .scrollableTable::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}

.customer-screen-headers {
    display: flex;
    justify-content: space-between;
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
    padding: 6px;
    min-height: 28px;
}

.customer-screen-headers h2 {
    padding-bottom: 0px;
    text-align: left;
    font-size: 0.85rem;
    align-self: center;
}

.customer-screen-headers .right-side-component {
    margin-right: 5px;
    font-size: 0.85rem;
}

.facilites-inner-list-item{
    display: flex;
    justify-content: space-between;
}

.facilites-inner-list-item span{
    line-height: 10px;
}

.customer-edit-button-container {
    display: flex;
    align-items: center;
    /* padding-bottom: 2px; */
}

.buttons-customer{
    background-color: rgb(168, 168, 168);
    font-size: 12px;
    height: 18px;
    margin: 0px;
    margin-left: 5px;
    cursor: pointer;
}

.done-button-customer:hover {
    cursor: pointer;
}

.done-button-customer {
    background-color: #028eaf;
}

.withdraw-consent-button-customer:hover {
    cursor: pointer;
}

.withdraw-consent-button-customer {
    background-color: #e86035;
}

.buttons-customer:disabled {
    background: rgba(2, 142, 175, .6);
    cursor: default;
}

.cancel-buttons-customer:disabled {
    background: rgb(168, 168, 168);
    cursor: default;
}

.info-input-customer {
    height: 20px;
    margin: 0px;
    font-size: 0.71rem;
    padding: 0px;
    padding-left: 2.5px;
    width: unset;
    max-width: 250px;
    min-width: 170px;
}


.em.customer {
    white-space: wrap;
    word-break: break-all;
}

.email-input-checkbox-container {
    display: flex;
    flex-direction: column;
}

.email-checkbox-lable-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top:2.5px;
}

.update-user-email {
    height: 9px;
    margin: 0 0.5px;
}
.update-user-email-label {
    font-size: 0.7rem;
    margin-right: 8px;
}

.collapsable-facilities-list {
    padding-left: 0px;
    margin-left: 0px;
}

.collapsable-facilities-list ul {
    padding-left: 0px;
}

.collapsable-facilities-list li {
    margin-left: 20px;
}

.collapsable-facilities-list.customer-facility-container>ul:nth-child(even) {
    background: #f3f3f3;
}

.collapsable-facilities-list.customer-facility-container ul li{
    background:transparent;
    border-bottom: 0.5px solid #ddd;
}

.collapsable-facilities-list.customer-facility-container ul>li:last-child {
    border-bottom: 0px;
}

@media screen and (max-width: 960px) {
    .tab-container-customer {
        width: 98%;
        margin: 5px auto;
     }
    .tab-container-customer.users-tab-row {
        margin: 5px auto;
     }
    
}

@media screen and (max-width: 1024px) {
    .container {
        grid-template-columns: 1fr 1fr;
    }
    
    .containt-container.rightSide {
        grid-column: span 2;
    }
}

@media screen and (max-width: 670px) {
    .container{
        grid-template-columns: 1fr;
        height: unset;
        min-height: calc(100vh - 40px);
    }

    .customer-info-container {
        padding-bottom: 0px;
    }
    
    .containt-container.rightSide {
        grid-column: 1;
        min-height: calc(60vh - 40px);
        max-height: calc(100vh - 40px);
    }
}

@media screen and (max-width: 245px) {
    .customer-edit-button-container {
        flex-wrap: wrap-reverse;
        justify-content: flex-end;
    }
    .buttons-customer {
        margin-top: 2.5px;
        max-width: 65px;
    }
}

@media screen and (max-height: 950px) {
    .containt-container.rightSide {
        grid-template-rows: 1fr 6fr;
    }
}

@media screen and (max-height: 788px) {
    .containt-container.rightSide {
        grid-template-rows: 1fr 5fr;
    }
}

@media screen and (max-height: 670px) {
    .containt-container.rightSide {
        grid-template-rows: 1fr 4fr;
    }
}

@media screen and (max-height: 550px) {
    .containt-container.rightSide {
        grid-template-rows: 1fr 3fr;
    }
}