/* Copyright Levelise Ltd 2024 */
.popup {  
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0,0.3);
    z-index: 1;
  }  

  .popup-inner {  
    position: absolute;  
    width: 350px;
    height: 180px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-box-shadow: 0px 0px 3px 0.5px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 3px 0.5px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 3px 0.5px rgba(0,0,0,0.5);
    border-radius: 2px;
    background: white;
  }

  .popup-btn-wrapper {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .popup-text {
      font-size: 14px;
      margin: 24% auto;
  }