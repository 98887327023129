/* Copyright Levelise Ltd 2024 - 2025 */
.add-customer-form-with-header {
    border-radius: 3px;
    overflow: hidden;
    height: inherit;
}

.add-customer-form-header {
    display: flex;
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
    padding: 5px;
    justify-content: space-between;
}

.top-bar-title-icon-container {
    display: flex;
    align-items: center;
}

.filter-icon-style {
    width: 0.72rem;
    margin-right: 2.5px;
}

@media screen and (max-width: 360px) {
    .add-customer-form-with-header {
        min-width: 98%;
    }
}