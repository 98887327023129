/* Copyright Levelise Ltd 2024 */
.add-customer-label-input-container,
.add-customer-buttons-container {
    min-width: 235px;
}

.add-customer-form-container {
    width: 100%;
    height:  calc(46vh - 13px);
    padding-top: 5px;
    overflow: hidden;
    overflow-y: auto;
}


.add-customer-label-input-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
}

.label-col {
    grid-column: 1/3;
    text-align: end;
}

.input-col {
    grid-column: 3/6;
}

.add-customer-label-input-container input{
    max-width: 170px ;
    /* margin: 5px; */
    padding-left: 4px;
    min-width: 162px;
}

.add-customer-label-input-container label{
    font-size: 0.7rem;
    font-family:  'Inter-Bold';
    padding-right: 5px;
}

.add-customer-form-container .add-customer-label-input-container {
    margin-top: 10px
}

.add-customer-buttons-container {
    margin-top: 10px;
    text-align: start;
}

.radio-input-label {
    display: flex;
    align-items: center;
    padding-top: 2.5px;
}

.SpecInput.date-input::-webkit-input-placeholder {
    color: 'rgb(128, 128, 128)'
}
.SpecInput.date-input::-moz-placeholder {
    color: 'rgb(128, 128, 128)'
}

.SpecInput.date-input::placeholder {
    color: 'rgb(128, 128, 128)'
}

.add-customer-form-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
  
.add-customer-form-container::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}
  
.add-customer-form-container::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}

@media screen and (max-width: 1023px) {
    .add-customer-form-container {
        height:  calc(46vh - 35px);
    }
}
