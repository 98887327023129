/* Copyright Levelise Ltd 2023 - 2024 */
.responsiveText {
    font-size: 0.75rem;
}

.scrollableTable th {
    font-size: 0.75rem;
    border-bottom: 0.1px solid rgba(187, 187, 187, 0.33);
}

.scrollableTable td {
    border-bottom: 1px solid #e7e7e7;
}

.table-col-1 {
    width: 5%;
}

.table-col-2 {
    width: 15%;
}
.table-col-3{
    width: 40%;
}
.table-col-4 {
    width: 20%
}
.table-col-5 {
    width: 45%;
}

.table-col-6 {
    width: 6%;
}

.table-col-7 {
    width: 71%;
}

.table-col-8 {
    width: 25%;
}

.credit-table-inputs {
    width: 99%;
    height: 27px;
}

.first-column-padding {
    padding-left: 1%;
}

.credits-balance-and-buttons-con {
    display: flex;
    align-items: center
}

.credits-radio-button {
    background-color: transparent;
    border: 0px;
    color: #000;
}

.credits-radio-button:hover {
    cursor: pointer;
}

.credits-radio-button:disabled {
    color: #000;
    cursor: unset;
}

.add-credits-popup-inner {  
    position: absolute;  
    width: 80%;
    max-width: 1000px;
    min-height: 180px;
    max-height: 80%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-box-shadow: 0px 0px 3px 0.5px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 3px 0.5px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 3px 0.5px rgba(0,0,0,0.5);
    border-radius: 2px;
    background: white;

    display: flex;
    flex-direction: column;
  }

.full-width {
    width: 100%
}

.dropdown-input-credits {
    border: 1px solid grey;
    border-radius: 3px;
}

@media (max-width: 645px) {
    .add-credits-popup-inner {   
        width: 95%;
    }
}
