/* Copyright Levelise Ltd 2019-2024 */
.report-time {
    font-family:'Inter-Medium'; 
    border: 1px solid rgba(255,0,0,0.5);
    color: #ccc;
    font-weight: 600;
    font-size: 11px;
}

/* animate svg */
@keyframes flow {
    0% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 20;
    }
}
@-webkit-keyframes flow {
    0% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 20;
    }
}

.power-flow-diagram.path  {
    stroke-dasharray: 16, 4;
    stroke-width: 4;
}

.power-flow-diagram.path .flow {
    stroke-dasharray: 8, 6;
    stroke-width: 2.5;
}

.power-flow-diagram.path.dc {
    stroke-dasharray: 4, 6;
    stroke-width: 2;
}

.power-flow-diagram.path.animation {
    animation: flow 0s linear 0s infinite normal;
    -webkit-animation: flow 1s linear 0s infinite normal;
    animation-direction: reverse;
    -webkit-animation-direction: reverse;
}

.power-flow-diagram.path.animation.reverse {
    animation-direction:normal;
    -webkit-animation-direction: normal;
}

.flow-value {
    font-size: 0.6rem;
}

.temp-value {
    font-size: 0.5rem;
}

.path-values {
    font-size: 12px;
    z-index: 10;
}

@media screen and (max-width: 1439px) {
    .flow-value {
        font-size: 0.55rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .flow-value {
        font-size: 0.53rem;
    }
}


@media (min-width: 426px) and (max-width: 768px) {
    .flow-value {
        font-size: 0.53rem;
    }

    .report-time {
        font-size: 0.8rem;
    }
}
  
  
@media (min-width: 376px) and (max-width: 425px) {
    .flow-value {
        font-size: 0.53rem;
    }

    .temp-value {
        font-size: 0.45rem;
    }

    .report-time {
        font-size: 0.75rem;
    } 
}

@media screen and (max-width: 375px) {
    .flow-value {
        font-size: 0.43rem;
    }

    .temp-value {
        font-size: 0.4rem;
    }

    .report-time {
        font-size: 0.7rem;
    }
}
