/* Copyright Levelise Ltd 2019-2023 */
.line-graph {
    /* height: 100%; */
    height: 917px;
    width: 100%;
    background: white;
    border-radius: 3px;
}

.facility-graphs-wrapper {
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 3px;
    border: 1px solid rgba(187, 187, 187, 0.33);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 1px;
}

div.facility-graphs {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

div.facility-graphs div.left-graphs,
div.facility-graphs div.right-graphs {
    width: 50%;
}

.toggle-grid-flow {
    display: flex;
}

.toggle-grid-flow input {
    display: none;
}

.toggle-grid-flow label {
    float: left;
}

.toggle-grid-flow label {
    display: inline-block;
    font-size: 0.6rem;
    background: white;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    width: 55px;
    padding: 4px 0;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition:    all 0.1s ease-in-out;
    -ms-transition:     all 0.1s ease-in-out;
    -o-transition:      all 0.1s ease-in-out;
    transition:         all 0.1s ease-in-out;
}
  
.toggle-grid-flow label:hover {
    cursor: pointer;
}
  
.toggle-grid-flow input:checked + label {
    background: rgba(16, 154, 192);
    color: white;
}
  
.toggle-grid-flow label:first-of-type {
    border-radius: 3px 0 0 3px;
}
  
.toggle-grid-flow label:last-of-type {
    border-radius: 0 3px 3px 0;
}

div.power-chart, 
div.demand-response-chart,
div.connectivity-chart,
div.battery-chart,
div.hotwater-chart,
div.dru-state-chart,
div.ac-line-chart {
    width: auto;
    overflow: hidden;
    background: white;
    /* border: 0.1px solid rgba(187, 187, 187, 0.33); */
}

/* Collapsible panel */
.coll-panel {
    position: relative;
    border: 0.5px solid rgb(255, 255, 255);
    /* border-bottom: none; */
}

.coll-panel-title {
    position:absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 19px;
    font-size: 10.5px;
    font-weight: 600;
    font-family: 'Inter-Medium';
    color: rgb(95, 95, 95);
    padding-bottom: 3px;
    padding-top: 10px;
    background-color: 'grey';
    cursor: pointer;
}

.coll-panel-title-collapsed {
    padding-top: 1px;
    background-color: rgb(233, 233, 233);
}

.coll-panel-component {
    height: 100%;
}

.coll-collapsed {
    height: 0%;
    /* display: none; */
}

@media  screen and (min-width: 1441px) {
    .line-graph {
        height: 100%;
    }
}

@media screen and (max-width: 1880px) {

}

@media screen and (max-width: 1440px) {
    .line-graph {
        height: fit-content !important;
    }
}

@media screen and (max-width: 1024px) {
    .line-graph {
        height: fit-content !important;
    }
}
  
@media (min-width: 426px) and (max-width: 668px) {

}

@media (min-width: 320px) and (max-width: 425px) {

}