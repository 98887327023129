/* Copyright Levelise Ltd 2024 */
.collapsable-list-common  {
    padding: 0px;
    margin: 0px;
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
}
.collapsable-list-common li {
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
}

.collapsable-list-common>li:nth-child(even) {
    background: #f3f3f3;
}

.em.expandable {
    white-space: wrap;
    word-break: break-all;
    
}
.strong.expandable {
    white-space: nowrap;
}