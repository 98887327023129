/* Copyright Levelise Ltd 2024 */

.user-container {
    padding: 0 3px 3px;
    margin: 45px auto 5px;
    width: 100%;
    gap: 3px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vh - 50px);
}
.content-container {
    height: fit-content;
    border: 1px solid rgba(187, 187, 187, 0.33);
    border-radius: 3px;
}

/* Settings */

.settings-form {
    width: 100%;
    margin: auto;
    padding: 5px 0 5px;
    border-top: 1.2px solid #ebebeb;
}

.fleet-preferences,
.facility-preferences  {
    width: 100%;
    margin: 10px auto;
    position: relative;
    border-top: 1.5px solid #ebebeb;
    /* border-bottom: 2px solid #ebebeb; */
    padding: 8px 0 15px;
}

.facility-preferences-title,
.fleet-preferences-title {
    font-size: 0.75rem;
    font-weight: 600;
    position: absolute;
    left: 15px;
    top: -8px;
    background: white;
    padding: 0 5px;
}

.fleet-preferences div.preference-wrapper,
.facility-preferences div.preference-wrapper {
    font-size: 0.75rem;
    display: flex;
    margin: 2px 0;
    justify-content: center;
    flex-direction: row;
}

.fleet-preferences div .form-left,
.facility-preferences div .form-left {
    width: 130px;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px 0 0;
}

.settings-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 10px 22px;
}

@media all and (max-width: 432px){
    .settings-form {
        width: 100%;
        margin: auto;
        padding: 15px 0 10px;
        border-top: 1.2px solid #ebebeb;
    }

    .settings-btn-wrapper {
        margin: 10px 20px;
    }
}

@media all and (max-width: 425px){ 
    .fleet-preferences div.preference-wrapper,
    .facility-preferences div.preference-wrapper {
        flex-direction: column;
        margin-bottom: 3px;
        align-items: center;
    }

    .fleet-preferences div .form-left,
    .facility-preferences div .form-left {
        width: 100%;
        padding-left: 15px;
        justify-content: flex-start;
    }

    .settings-btn-wrapper {
        margin-right: 20px;
    }
}

@media screen and (max-width: 800px ) {
    .user-container {
        grid-template-columns: 1fr;
        gap: 5px;
    }
}