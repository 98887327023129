/* Copyright Levelise Ltd 2019-2024*/
.aggFacilities-wrapper {
    margin: 2.5px !important; 
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 413.5px;
    overflow: hidden;
    background: white;
    border: 1px solid rgba(187, 187, 187, 0.33);
}

ul.aggFacilities {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    list-style: none;
    text-align: left;
    height: 391px;
    overflow-x: hidden;
    -webkit-overflow-y: hidden;
    -moz-overflow-y: hidden;
    -ms-overflow-style: none;
    top: 90px;
    bottom: 0;
}

ul.aggFacilities li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.72rem;
    border-bottom: 1px solid #e7e7e7;
    overflow: hidden;
    min-height: 25px;
}

ul.aggFacilities li div {
    display: flex;
    justify-content: space-between;
    padding: 4px 6px;
    height: 20px;
}

ul.aggFacilities li div.af-name {
    cursor: pointer;
}

ul.aggFacilities li div.af-name.last-slot {
    padding: 3.5px 6px;
}

ul.aggFacilities > li:nth-child(even) {
    background: #f3f3f3;
}

.af-groupName{
    font-weight: 700;
}

.af-groupName:hover{
    cursor: pointer;
}

.hoverEffectForFleet:hover{
    background: rgba(16, 154, 192, 0.2);
}

table.fs-contract {
    margin-left: 0px;
}


table.af-contacts td,
table.af-contacts th {
    font-size: 0.65rem;
    height: 14px;
    border: 1px solid #e0e0e0;
    background:white;
    text-align: center;
    justify-items: center;
}

table.af-contacts td.contract-cell,
table.af-contacts th.contract-cell {
    width: 25px;
    padding: 0;
}

table.af-contacts td.contract-cell {
    position: relative;
}

table.af-contacts .contract-header th#day-th,
table.af-contacts .contract-header th {
    background: rgb(85, 85, 85);
    border: rgb(85, 85, 85);
    color: white;
}

table.af-contacts th {
    background: #e0e0e0;
    color: black;
}

table.af-contacts .contract-header th#empty-th {
    background: None;
    border: None;
}

table.af-contacts td span.contract-box {
    min-height: 14px;
    display: flex;
    justify-content: flex-start;
}

table.af-contacts td span.contract-type {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    padding-bottom: 1px;
    font-size: 7px;
    color: black;
    font-family:'Inter-Medium';
}

ul.aggFacilities-extended li.selected-aggFacility,
ul.aggFacilities li.selected-aggFacility {
    background: linear-gradient(to bottom, rgba(130,202,198, 1) 20px, white 20px);
}
.fleet-table-title.selected-aggFacility {
    background: linear-gradient(to bottom, rgba(130,202,198, 1) 30px, white 20px);
}

ul.aggFacilities-extended li div.af-name:hover,
ul.aggFacilities-extended li div.af-name:focus,
ul.aggFacilities-extended li div.af-name:nth-child(even):hover,
ul.aggFacilities-extended li div.af-name:nth-child(even):focus,
ul.aggFacilities li div.af-name:hover,
ul.aggFacilities li div.af-name:focus,
ul.aggFacilities li div.af-name:nth-child(even):hover,
ul.aggFacilities li div.af-name:nth-child(even):focus {
    background: rgba(16, 154, 192, 0.2);
}

ul.aggFacilities::-webkit-scrollbar-track,
ul.aggFacilities-extended::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

ul.aggFacilities::-webkit-scrollbar,
ul.aggFacilities-extended::-webkit-scrollbar{
    width: 5px;
    background-color: #F5F5F5;
}

ul.aggFacilities::-webkit-scrollbar-thumb,
ul.aggFacilities-extended::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
} 

@media screen and (min-width: 1441px) {
    ul.aggFacilities-scroll-hidden {
        /* overflow: hidden; */
    }
}

@media screen and (max-width: 799px) {
    table.af-contacts {
        padding: 2px 2px 2px 0;
        margin: auto;
        width: 180px;
    }

    table.fs-contract {
        margin: auto;
    }
}
