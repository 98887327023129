/* Copyright Levelise Ltd 2019-2024 */
div.tableContainer {
    margin: 2.5px;
    font-size: 0.75rem;
    clear: both;
    border: 1px solid rgba(187, 187, 187, 0.33);
    height: 395px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 400px;
    border-radius: 3px;
    position: relative;
}

.col-1 {
    width: 45px;
}

.col-2 {
    width: 80px;
}

.col-3.left-data {
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* -ms-word-break: break-all; */
    /* word-break: break-all; */
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

div.tableContainer table {
    float: left;
}
html>body div.tableContainer table {
    width: 100%;
}

.search {
    width: 260px;
    display: flex;
    position: absolute;
    top: 3px;
    right: 0px;
    justify-content: flex-end;
}

.searchType {
    font-size: 0.7rem;
    max-width: 110px;
    border: 1px solid rgb(221, 221, 221);
    border-right: none;
    padding: 2px 5px 2px 5px;
    border-radius: 2.5px 0 0 2.5px;
    outline: none;
}

.levelOptions {
    display: flex;
    align-content: center;
    align-items: center;
    width: 140px;
    padding: 0 4px 0 0;
    justify-content: space-between;
}

.searchTypeTerm:focus{
    color: rgb(168, 168, 168);
}

.searchTypeButton {
    border: 1px solid rgb(221, 221, 221);
    background: rgb(221, 221, 221);
    text-align: center;
    color: #fff;
    border-radius: 0 2px 2px 0;
    cursor: pointer;
}

.searchTypeButton:focus,
.searchTypeButton:hover {
    background: rgb(138, 138, 138);
    outline: none;
}

thead.fixedHeader > tr:nth-child(1) {
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
}

thead.fixedHeader th.col-search {
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
    font-size: 11.8px;
    padding: 6px 2px;
    text-align: center;
}

thead.fixedHeader th.col-name {
    background: white;
    font-weight: 600;
    padding: 5px 2px;
    text-align: center;
}

tbody.scrollContent {
    text-align: center;
    vertical-align: top;
    display: inline-grid;
    height: 342px;
    overflow: auto;
    width: 100.1%;
    padding-bottom: 30px;
}

thead.fixedHeader {
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
    display: table;
    overflow: auto;
    width: 100%
}

tbody.scrollContent td, 
tbody.scrollContent tr.normalRow td,
tbody.scrollContent tr.alternateRow td {
    padding: 4px 3px 4px 4px;
}

tbody.scrollContent tr {
    border-top: 1px solid #e7e7e7;
}

tbody.scrollContent tr:first-child {
    border-top: none;
}


.left-data {
    text-align: left;
}

tbody.scrollContent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
  
tbody.scrollContent::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}
  
tbody.scrollContent::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}

#tableContainer::-webkit-scrollbar {
    display: None;
}


div.tableContainer h2 {
    padding: 7px 5px 3px;
}

div.tableContainer h2 {
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
}

#log-message, #log-history, #log-tests {
    padding: 4px 8px 5px;
    cursor: pointer;
}

.log-view {
    background: white;
    border-top: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.qual-wrapper{
    border: 1px solid rgba(187, 187, 187, 0.5);
}

.qual-row  {
    border-bottom: 1px solid rgba(187, 187, 187, 0.5);
}

.qual-row:last-child{
    border-bottom: none;
}

.qual-row > .qual-col1 {
    display: inline-block;
    width: 40px;
    padding: 2px 0 1px 5px;
}

.qual-row > .qual-col2, .qual-row > .qual-col3 {
    display: inline-block;
    text-align: end;
    width: 60px;
    padding: 2px 5px 1px 0;
    border-left: 1px solid rgba(187, 187, 187, 0.5);
}

.log-header-selector{
    display: none;
}

select {
   font-family: 'Inter-Light';
   font-style: normal;
   font-size: 12px;
   font-weight: 300;
   padding: 2px 1px 0px 2px;
   margin-top: -5px;
   margin-bottom: 2px;
   border: 0.5px solid #ccc;
   border-radius: 5px;
   width: 70px;
   cursor: pointer;
}

select option{
    width: 100%;
    cursor: pointer;
}

.logHeaderIcon{
    font-size: 13px;
    margin-right: 3px;
}

@media  screen and (min-height: 800px) and (min-width: 1441px) {
    div.tableContainer {
        height: calc(100vh - 511px);
        max-height: calc(1500px - 511px);
        width: 420px;
        margin-left: 5px;
    }

    tbody.scrollContent {
        height: calc(100vh - 564px);
        max-height: calc(1500px - 564px);
    }
}

@media screen and (max-width: 1440px) {
    div.tableContainer {
        width: 100%;
        margin: 2.5px auto;
    }
}

@media (min-width: 1025px) and (max-width: 1350px) {
    .search{
        position: relative;
        
    }

    .searchContainer{
        border-bottom: 1px solid #dddddd;
        padding-bottom: 5px;
    }
}


@media screen and (max-width: 1024px) {
    div.tableContainer {
        margin: 2.5px 0 2.5px 5px;
    }
}

@media (min-width: 769px) and (max-width: 900px) {
    .search{
        position: relative;
        
    }

    .searchContainer{
        border-bottom: 1px solid #dddddd;
        padding-bottom: 5px;
    }
}

@media screen and (max-width: 768px) {
    div.tableContainer {
        margin: 2.5px auto;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .search{
        position: relative;
    }

    .searchContainer{
        border-bottom: 1px solid #dddddd;
        padding-bottom: 5px;
    }
}
