/* Copyright Levelise Ltd 2019-2025 */

.list-wrapper {
    height: 100%;
    border-radius: 3px;
}

.list-wrapper-extended {
    height: 100%;
    border-radius: 3px;
}

.fleet-route {
    margin: 40px auto 0;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.fleet-graph-container-wide{
    display: flex;
    flex-grow: 1;
}
.fleet-graph-container-narrow{
    display: none;
    width: 100%;
}

.fleet-status {
    display: flex;
    flex-direction: row;
    width: 99.9%;
    padding: 2.5px 0 2.5px 2.5px;
}

.fleet-table-title.dru-list-header,
.fleet-table-title.facility-list  {
    padding: 3.7px 5px;
}
.status-wrapper {
    margin: 2.5px 2.5px 2.5px 2.5px !important; 
}

/* Mozilla styles */
@-moz-document url-prefix('') {
    ul.facilities,
    ul.facilities-extended
    div ul.dru-list-extended,
    div ul.dru-list,
    ul.aggFacilities,
    ul.status-scroll {
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .strong.specification,
    .em.specification,
    .strong.status,
    .em.status {
        padding: 5.3px 5px;
    }
}

.aggFacilities-wrapper {
    height: 100%;
    flex-grow: 1;
}

#safari .aggFacilities-wrapper {
    height: 100%; 
}

ul.dru-list-extended {
    height: calc(100% - 26px);
}

ul.dru-list {
    height: calc(100% - 26px);
}
ul.facilities {
    height: calc(100% - 43px);
}

ul.facilities-extended {
    height: calc(100% - 43px);
}

.customer-list-item:hover {
    cursor: pointer;
}

.filter-icon-style {
    width: 0.72rem;
    margin-right: 2.5px;
}

@media screen and (min-width: 1025px) {
    section.fleet-route {
        /* height: calc(100vh - 45px); */
        max-height: auto;
    }
    
    .fleet-status.status-hidden .aggFacilities-wrapper,
    #safari .fleet-status.status-hidden .aggFacilities-wrapper {
        height: 100%;
    }
    
    .fleet-status.status-hidden ul.aggFacilities,
    #safari .fleet-status.status-hidden ul.aggFacilities {
        height: 100%;
    }

    .list-wrapper {
        height: 510px;
    }

    ul.facilities {
        height: calc(100% - 43px);
    }

    ul.facilities-extended {
        height: calc(100% - 43px);
    }

    ul.aggFacilities {
        height: calc(100% - 25px);
    }
    

    .fleet-status.status-hidden > div:last-child {
        flex: 1;
    }
}

@media screen and (min-width: 1440px) {
    section.fleet-route {
        height: calc(100vh - 45px);
        overflow: hidden;
    }

    .list-wrapper {
        height: 100%;
    }
    .fleet-status.status-hidden .aggFacilities-wrapper, #safari .fleet-status.status-hidden .aggFacilities-wrapper {
        height: 100%;
    }
    ul.status-scroll {
        min-height: 100%;
        padding-bottom: 20px;
     }
}

@media (max-height: 760px) and (min-width: 1440px) {
    .fleet-status > div:first-child {
        display: flex;
    }

    .aggFacilities-wrapper {
        height: 100%;
        
    }

    .status-wrapper {
        height: 100%;
    }
}

@media screen and (max-width: 960px) {
    .fleet-graph-container-wide {
        display: none;
        
    }

    .fleet-graph-container-narrow {
        display: flex;
    }
    .fleet-status {
        flex-wrap: wrap;
        height: auto;
    }
    .customer-af-container {
        height: auto;
        width: 100%;
        
    }
    .status-drus-container {
        flex-wrap: wrap;
        width: 100%;
        
    }
    .list-wrapper {
        height: 510px;
    }

    .list-wrapper, .list-wrapper-extended{
        height: 510px;
    }
    .aggFacilities-wrapper {
        height: 510px;
    }
}

@media (max-width: 1300px)  { 
    .fleet-route {
        margin: 66px auto 0;
    }
}

@media (min-width: 660px) and (max-width: 1440px) { 
    .fleet-route {
        flex-direction: column;
    }

    .fleet-graph-container-wide {
        display: none;
    }

    .fleet-graph-container-narrow {
        display: flex;
    }

    .fleet-status {
        flex-direction: row;
        padding: 2.5px 2.5px 2.5px 2.5px;
        
    }

    #safari .fleet-status {
        flex-direction: row;
    }

    .fleet-status > div:first-child {
        display: flex;
    }

    .fleet-status.status-hidden,
    #safari .fleet-status.status-hidden {
        flex-direction: row;
        height: auto;
    }

    .line-graphs-wrapper.fleet-graphs-wrapper {
        height: auto
    }

    .fleet-line-graph {
        margin-top: 2.5px !important;
    }

    .status-wrapper {
        margin: 2.5px 2.5px 2.5px 2.5px; 
    }

    .status-wrapper {
        width: 215px;
    }

    .fleet-status.status-hidden .aggFacilities-wrapper,
    #safari .fleet-status.status-hidden .aggFacilities-wrapper {
        
        height: 510px;
    }

    .aggFacilities-wrapper {
        height: 510px; 
    }
    #safari .aggFacilities-wrapper {
        height: 510px; 
    }

    .status-wrapper{
        height: 510px;
    }
    #safari  .status-wrapper{
        height: 510px;
    }

    .list-wrapper {
        width: auto;
        height: 510px;
    }

    .list-wrapper-extended {
        height: 510px;
        margin: 2.5px 2.5px;
        width: -webkit-fill-available;
        width: -moz-available;
    }

    ul.aggFacilities {
        height: 510px;
    }
}

@media (max-height: 760px) and (max-width: 1440px)  { 
    .fleet-route {
        flex-direction: column;
    }

    .fleet-graph-container-wide {
        display: none;
    }

    .fleet-graph-container-narrow {
        display: flex;
    }

    .fleet-status {
        flex-direction: row;
        padding: 2.5px 2.5px 2.5px 2.5px;
        
    }

    #safari .fleet-status {
        flex-direction: row;
    }

    .fleet-status > div:first-child {
        display: flex;
    }

    .fleet-status.status-hidden,
    #safari .fleet-status.status-hidden {
        flex-direction: row;
        height: auto;
    }

    .line-graphs-wrapper.fleet-graphs-wrapper {
        height: 90%;
    }

    .fleet-line-graph {
        margin-top: 2.5px !important;
    }

    

    .status-wrapper {
        width: 215px;
    }

    .fleet-status.status-hidden .aggFacilities-wrapper,
    #safari .fleet-status.status-hidden .aggFacilities-wrapper {
        
        height: 510px;
    }

    .aggFacilities-wrapper {
        height: 510px; 
    }
    #safari .aggFacilities-wrapper {
        height: 510px; 
    }

    .status-wrapper{
        height: 510px;
    }
    #safari  .status-wrapper{
        height: 510px;
    }

    .list-wrapper {
        width: auto;
        height: 510px;
    }

    .list-wrapper-extended {
        height: 510px;
        margin: 2.5px 2.5px;
        width: -webkit-fill-available;
        width: -moz-available;
    }

    ul.aggFacilities {
        height: 510px;
    }
}

@media screen and (max-width: 768px) {
    .fleet-route {
        flex-direction: column;
        margin: 76px auto 0;
    }
}

@media  (max-width: 660px) {
    .fleet-route {
        flex-direction: column;
        margin: 96px auto 0;
    }

    .fleet-graph-container-wide {
        display: none;
    }

    .fleet-graph-container-narrow {
        display: flex;
        padding-left: 2.5px;
        padding-right: 2.5px;
    }


    .fleet-status > div:last-child {
        margin-left: 0;
    }

    .fleet-status > div:first-child {
        display: flex;
        flex-direction: column;
    }

    .fleet-status {
        flex-direction: column;
        padding: 2.5px 5px;
        height: auto;
    }

    .fleet-status > div:last-child {
        margin-bottom: 0px;
    }

    #safari .fleet-status {
        height: auto;
    }

    .status-wrapper {
        margin: 5px 0 5px;
    }

    .fleet-status.status-hidden .aggFacilities-wrapper,
    #safari .fleet-status.status-hidden .aggFacilities-wrapper {
        width: auto;
    }

    .aggFacilities-wrapper,
    .status-wrapper,
    .list-wrapper {
        width: auto;
       
    }

    
    .fleet-status.status-hidden .aggFacilities-wrapper,
    #safari .fleet-status.status-hidden .aggFacilities-wrapper {
        margin: 2.5px 2.5px;
        height: 99%;
    }

    ul.aggFacilities {
        height: 510px;
    }
    #safari .aggFacilities-wrapper {
        height: 510px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .aggFacilities-wrapper {
        height: 510px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .list-wrapper,
    .list-wrapper-extended {
        height: 510px;
        width: auto;
    }
    
    .fleet-line-graph {
        margin-top: 2.5px;
    }

    .line-graphs-wrapper.fleet-graphs-wrapper {
        height: 50%;
    }
}

