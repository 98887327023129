/* Copyright Levelise Ltd 2022 */
div.summary {
    /* height: 456px; */
    /* min-width: 230px;
    max-width: 320px; */
    width: 420px;
    overflow: hidden;
    position: relative;
    /* background: #f3f3f3; */
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    margin: 2.5px 2.5px 2.5px 5px;
    background-color: white;
}

div.summary h2.summary-header {
    padding: 7px 5px 3px;
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
    position: relative;
}

#summary-scale{
    position:absolute;
    top: 7px;
    right: 10px;
}

#summary-data {
    padding: 4px 12px 5px;
    cursor: pointer;
}

#summary-chart {
    padding: 4px 15px 5px;
    cursor: pointer;
}

.summary-view {
    background: white;
    border-top: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

div.summary ul.energy-sums > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f3f3f3;
    border-bottom: 1px solid #e7e7e7;
}

div.summary ul.summary-table {
    height: 428px;
    padding: 0;
    margin: 0;
}

div.summary ul.summary-table > li,
div.summary ul.summary-table > li.odd,
div.summary ul.summary-table > li.even,
div.summary ul.summary-table > li.from,
ul.summary-table > li.from > span.strong,
ul.summary-table > li.from > span.em {
    height: 23.8px !important;
    list-style: none;
    padding: none;
    margin: none;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
}

div.summary ul.summary-table > li.odd {
    background: #f3f3f3;
}

div.summary ul.summary-table > li.even {
    /* background: white; */
}

div.summary ul.summary-table > li.from,
div.summary ul.summary-table > li.from {
    /* background: white; */
    padding-left: 20px;
}

ul.summary-table > li.from > span.strong,
ul.summary-table > li.from > span.em {
    display: inline-block;
}

div.summary ul.summary-table > li:last-of-type {
    border-bottom: none;
}

ul.summary-table {
    overflow: auto;
}
ul.summary-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
  
ul.summary-table::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}
  
ul.summary-table::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}

/* -------------------------------------------------- */

.summary .summary-chart {
    width: 100%;
    height: 428px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: none;
}


@media screen and (max-width: 1440px) {
    /* .pie-chart-wrapper {
        padding: 3px 0;
    } */

    .summary .summary-chart {
        width: 300px;
        margin: auto;
        padding-bottom: 5px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .summary-chart {
        height: 222px;
     }
}



@media screen and (max-width: 768px) {
    .summary-chart {
        width: 100%;
        margin: 2.5px auto;
    }
}
