/* Copyright Levelise Ltd 2020 */

.preferenceSeven.specs-wrapper div#top-left {
    margin: 0;
    width: 0;
}

@media screen and (min-width: 1881px) { 
    .preferenceSeven div.live-status {
        width: 100%;
        margin: 2.5px 2px 2.5px 0;
    }

    .preferenceSeven.specs-wrapper {
        width: 420px;
    }

    .preferenceSeven {
        height: 300px; 
        width: 400px;
    }

    .preferenceSeven .summary-chart {
        height: 577px;
    }

    .preferenceSeven .pie-chart-wrapper div {
        width: 330px;
    }
    
    .preferenceSeven .pie-chart-wrapper {
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: -webkit-center;
    }

    .preferenceSeven div#bottom-right {
        width: 99.6%;
    }

    .preferenceSeven div#bottom-left {
        width: 0;
    }
}

@media screen and (max-width: 1880px) {
    .preferenceSeven.specs-wrapper {
        width: 35%;
    }
}

@media (max-width: 1150px) and (min-width: 1025px) {
    .preferenceSeven.specs-wrapper {
        width: 40%;
    }
}


@media (min-width: 660px) and (max-width: 1024px) {
    .preferenceSeven.specs-wrapper {
        width: 100%;
    }

    .preferenceSeven div#top-right {
        width: 100%;
        display: flex;
    }
    
    .preferenceSeven div#top-left {
        width: unset;
    }

    .preferenceSeven div.live-status {
        min-width: 64%;
    }

    .preferenceSeven .summary-chart { 
        margin: 2.5px 0 2.5px 2.5px;
        min-width: 35%;
        height: unset;
    }

    .preferenceSeven .pie-chart-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .preferenceSeven .pie-chart-wrapper div {
        width: 205px;
    }
}

@media screen and (max-width: 659px) {
    .preferenceSeven.specs-wrapper {
        width: 100%;
    }
} 

