.alert-container {
    position: fixed;
    bottom: 20px;
    left: calc(50% - 150px);
    transform: translateY(-calc(50% - 150px));
    display: 'flex';
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
}

.alert {
    width: 300px;
    display: flex;
    align-items: center;
    padding: 10px 7.5px 10px 10px;
    border-radius: 5px;
    color: white;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    will-change: opacity, transform;
    background-color: #000;
    margin-top: 10px;
}

.alert span {
    flex-grow: 1;
    font-family: 'Inter-Light';
    font-weight: 300;
    margin-inline: 5px;
}

.alert button {
    background-color: transparent;
    border-style: none;
}

.fade-out {
    opacity: 0;
    transform: translateY(20px);
}