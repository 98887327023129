/* Copyright Levelise Ltd 2019-2023*/

.Input {
    display: block;
    width: 100%;
    height: 2rem;
    padding: 5px 0px 5px 10px;
    margin: 0.5rem auto;  
    border: 1px solid #DDDDDD;
    font-size: 0.8rem;
    font-family: 'Inter-Light';
    border-radius: 3px;
    outline: none;
    color: gray;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    cursor: pointer;
}

.Input:focus {
    box-shadow: 0 0 5px rgb(34, 154, 187);
    border: 1px solid  rgb(34, 154, 187);
}

.Input::placeholder{
    font-family: 'Inter-Light';
}

.Data-list {
    width: 90%;
    padding: 5px 0px 5px 10px;
    margin: 0.5rem auto;  
    border: 1px solid #DDDDDD;
    font-size: 0.8rem;
    border-radius: 3px;
    outline: none;
    color: gray;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    cursor: pointer;
}

.Button {
    width: 90%;
    height: 3rem;
    margin: 5px auto;
    font-size: 1.2rem;
    border-radius: 3px;
    border: none;
    background: #028eaf;
    color: white;
    font-family: 'Inter-Light';
}

.disabled-button {
    background: rgb(173, 207, 216);
    color: white; 
}

.grey-btn {
    background: #424242;
}
