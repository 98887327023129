/* Copyright Levelise Ltd 2020 */

.preferenceOne div.tableContainer {
    width: 100%;
    margin: 2.5px 2.5px 0 0;
}

@media (min-width: 660px) and (max-width: 768px) {
    .preferenceOne div#top-right {
        width: 100%;
        display: flex;
    }
    
    .preferenceOne div#top-left {
        width: unset;
    }

    .preferenceThree div.live-status {
        min-width: 64%;
    }

    .preferenceOne .summary-chart { 
        margin: 2.5px 0 2.5px 2.5px;
        min-width: 35%;
        height: unset;
    }

    .preferenceOne .pie-chart-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .preferenceOne .pie-chart-wrapper div {
        width: 205px;
    }
}

@media (min-width: 769px) and (max-width: 1440px) {
    .preferenceOne div.tableContainer {
        margin: 2.5px 0 0 0;
    }
}