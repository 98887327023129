/* Copyright Levelise Ltd 2019-2025 */
section.facility-route {
    margin: 40px 2.5px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
}

div.live-status {
    margin: 2.5px auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    background: white;
    border-radius: 3px;
    border: 1px solid rgba(187, 187, 187, 0.33);
}

ul.timeframes li {
    cursor: pointer;
}

div.specs-wrapper {
    max-width: 800px;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 2.5px 0 2.5px 2.5px;
    align-content: baseline;
    justify-content: flex-start;
}

.specs-wrapper #top-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

div#bottom-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

div.charts-wrapper {
    padding: 5px 2.5px;
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    width: -moz-available;
}

/* Mozilla styles */
@-moz-document url-prefix('') {
    ul.facility-specifications {
        height: 366.2px;
    }

    li span.strong.specification,
    li span.em.specification,
    li span.strong.status,
    li span.em.status {
        padding: 5.3px 5px;
    }

    ul.energy-sums > li.from > span.strong, ul.energy-sums > li.from > span.em {
        padding: 1.9px 5px;
    }

    .summary-header-wrapper {
        padding: 4px;
    }

    tbody.scrollContent,
    ul.scroll-fac-spec {
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .facility-select-options li#right-space {
        width: 25px;
        border-bottom: 1px solid #e7e7e7;
    }
}

@media  screen and (min-height: 800px) and (min-width: 1441px) {
    section.facility-route {
        height: calc(100vh - 40px);
        max-height: calc(1500px - 40px);
    }

    .live-status-body {
        height: 428px;
    }
}

@media  screen and (max-width: 1440px) {
    div.specs-wrapper {
        width: 35%;
        margin-right: 2px;
    }

    .specs-wrapper #top-section {
        flex-direction: column;
    }

    .specs-wrapper #bottom-section {
        flex-direction: column-reverse;
    }
    
    div.charts-wrapper {
        width: 65%;
    }

    div.live-status {
        width: 100%;
    }

    div.summary { 
        width: 100%;
        margin: 2.5px 2.5px 2.5px 0;
    }
}

@media  (min-width: 1025px) and (max-width: 1150px) {
    div.specs-wrapper {
        width: 40%;
    }

    div.charts-wrapper {
        width: 60%;
    }
}

@media screen and (max-width: 1300px) {
    section.facility-route {
        margin: 66px 2.5px 0;
    }
}

@media screen and (max-width: 1024px) {
    div.specs-wrapper {
        max-width: none;
        width: 100%;
        padding: 2.5px;
        margin-right: 0px;
    }

    div.charts-wrapper {
        max-width: none;
        width: 100%;
        padding: 0 2.5px 0 2.5px;
        margin-right: 0px;
    }


    .specs-wrapper #top-section {
        flex-direction: row;
    }

    div.energy-sums-wrapper {
        width: 100%;
    }

    section.facility-route {
        flex-direction: column;
    }

    div.summary { 
        width: 420;
        margin: 2.5px 0px 2.5px 5px;
    }

    .specs-wrapper #bottom-section {
        display: flex;
        flex-direction: row;
    }
}

@media (min-width: 660px) and (max-width: 768px) {
    div#top-right {
        width: 100%;
        display: flex;
    }
    
    div#top-left {
        width: unset;
    }

    div.live-status {
        min-width: 64%;
    }

    .summary-chart { 
        margin: 2.5px 0 2.5px 2.5px;
        min-width: 35%;
        height: unset;
    }

    .pie-chart-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .specs-wrapper .pie-chart-wrapper div {
        width: 205px;
    }
}

@media screen and (max-width: 768px) {
    .specs-wrapper #top-section {
        flex-direction: column;
    }

    .specs-wrapper #bottom-section {
        flex-direction: column-reverse;
    }

    div.summary .summary-chart, 
    div.summary ul.summary-table {
        height: 100%;
    }

    div.summary { 
        width: 420;
        margin: 2.5px 5px 2.5px 0px;
    }
}

@media screen and (max-width: 768px) {
    section.facility-route {
        margin: 76px 2.5px 0;
    }
}
@media screen and (max-width: 660px) {
    section.facility-route {
        margin: 96px 2.5px 0;
    }
}
