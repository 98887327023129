/* Copyright Levelise Ltd 2019 - 2023 */
@font-face {
  font-family: 'Inter-Light';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/fonts/Inter-Light.ttf');
}
@font-face {
  font-family: 'Inter-Medium';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/Inter-Medium.ttf');
}
@font-face {
  font-family: 'Inter-Bold';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/Inter-Bold.ttf');
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  background: white;
  font-family:  'Inter-Medium',sans-serif;
}

code {
  font-family: 'Inter-Medium', sans-serif;
}
