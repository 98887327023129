/* Copyright Levelise Ltd 2019-2025 */
nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    margin: 0 auto;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    z-index: 1;
}

.sm-logo {
    height: 2.38rem;
}

.m-logo {
    height: 16px;
    padding-left: 5px;
    padding-right: 5px;
}

.left {
    position: relative;
    text-align: start;
    order: 0;
    border-bottom: 1px solid rgb(187, 187, 187);
}

.left-flex {
    display: flex;
    align-items: center;
    height: 38px;
    flex-grow: 1;
}

.left a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
}

.back-logo-customer-container{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-name-container {
    height: 39px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: flex-end;
    order: 2;
    
    height: 38px;
    border-bottom: 1px solid rgb(187, 187, 187);
}

.profileIcon {
    height: 1.1rem;
    
}
.group-icon-container {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    overflow: hidden;
}

.group-icon-container:hover {
    cursor: pointer;
}

.clipboard-icon-container {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin-right: 5px;
    overflow: hidden;
    
    margin-inline: auto;
}

.resizable-icon {
    transition: all .2s ease-in-out
}

.hide-able-search-input {
    display: flex;
    align-items: center;
    max-width: 82px;
    animation-name: appear-from-left;
    animation-duration: .4s;
}

@keyframes appear-from-left {
    from {
        opacity: 0;
        padding-right: 80px
    }
    to {
        opacity: 1;
        padding-right: 0;
    }
}

.clipboard-icon-container:hover {
    cursor: pointer;
}

.group-icon {
    height: 1.1rem;
    position: relative;
    width: 20px;
}

.group-icon-1 {
    left: 10.5px;
}

.group-icon-2 {
    top: 2px;
    left: 1px;
    z-index: 2;
}

.group-icon-3 {
    left: -9px;
}

button.username {
    border: 0;
    background: white;
    font-size: .9rem;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    min-width: 70px;
    outline: none;
    display: flex;
    align-items: center;
    font-family: 'Inter-Medium';
    color: #000;
    height: 95%;
}

.select-timezone-wrapper {
    margin: 0 15px 0 0;
    font-size: 0.7rem;
}

select.timezone-options {
    padding: 5px 18px 5px 5px;
    font-size: 0.7rem;
    color: #60666d;
    outline: none;
    background-color: white;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';
    border: none;
    border-bottom: 1px solid rgba(187, 187, 187, 0.8);
}

select::-ms-expand {
    display: none;
}

select.timezone-options option:hover,
select.timezone-options option:focus {
    background: rgb(11, 126, 158, 0.8)
}

.timeframes-select-timezone-wrapper {
    display: none;
}

.tab-underline {
    height: 2px;
    width: 100%;
}

.tab-underline.active {
    background-color: #028eaf;
}

.tab-underline.inactive {
    background-color: #000;
}

.logout-text {
    font-size: unset;
}

.navbar-separator {
    height: 75%;
    margin-block: auto;
    width: 1px;
    background-color: rgb(219, 219, 219, 0.5);
}

.user-name-initials-container {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #64B664;
    margin-left: 6px
}

.user-name-initials-container span {
    font-size: .7rem;
    font-family: 'Inter-Light';
}

.username-text {
    font-size: 0.81rem;
}

.nav-bar-go-to-id-input::placeholder {
    font-size: .59rem;
    font-family: 'Inter-Light';
}

@media all and (max-width: 1300px) {
    .showFramesShow {
        flex-wrap: wrap;
        height: 65px; 
    }

    .left{
        padding-left: 10px;
        
    }

    .right{
        /* flex-wrap: wrap-reverse; */
        order: 1;
        flex-grow: 1;
    }
}

@media screen and (max-width: 768px) {
    .showFramesShow {
        height: 74px; 
    }

    .username.long-name {
        font-size: 0.7rem;
    }
}
@media screen and (max-width: 660px) {
    .showFramesShow {
        height: 94px; 
    }

    .left {
        width: 100%
    }
    .right.with-boarder {
       height: 24px
    } 

    .logout-text {
        display: none;
    }

    .username-text {
        display: none;
    }

    .username-button {
        min-width: 50px !important;
        justify-content: center;
    }

    button.logout-button {
        min-width: 30px
    }

    .username.long-name {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 340px) {
    button.username {
        font-size: 0.65rem;
    }
}
