/* Copyright Levelise Ltd 2019-2024 */
div.facility-specification-wrapper {
    margin: 2.5px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 3px;
    border: 1px solid rgba(187, 187, 187, 0.33);
}

div.facility-specification-wrapper {
    width: 375px;
    margin: 2.5px 0;
    position: relative;
}

div.command-btn-wrapper {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 5px;
    right: 3px;
}

button.command.btn-confirm,
button.command.btn-cancel {
    letter-spacing: 0.8px;
    width: 100%;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0;
    height: 32px;
    outline: none;
    cursor: pointer;
}

button.btn-kick,
button.btn-restart {
    letter-spacing: 0.5px;
    width: 48px;
    margin: 0 1px;
    font-size: 10px;
    font-weight: 300;
    border-radius: 1.5px;
    height: 18px;
    outline: none;
    cursor: pointer;
}

button.btn-kick,
button.btn-restart {
    background: rgb(168, 168, 168);
}

button.command.btn-confirm {
    background: #028eaf;
}

button.command.btn-cancel {
    background: rgb(168, 168, 168);
}

button.command.btn-confirm:hover {
    border: none;
    outline: none;
}

button.btn-cancel:hover,
button.btn-kick-hover:hover,
button.btn-restart-hover:hover {
    border: none;
    outline: none;
}

.btn-kick.btn-kick-hover.btn-success {
    background-color: #028eaf;
}
.btn-kick.btn-kick-hover.btn-success:disabled {
    background-color:  rgba(2, 142, 175, .6);
    cursor:unset
}

button.command.btn-confirm:disabled {
    background: #ddd;
    cursor:unset
}

.command.popup-text {
    font-size: 14px;
    margin: 9% auto;
}

.command.popup-inner {
    background: white;
    height: 120px;
}

div.facility-specification-wrapper h2 {
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
    padding: 5px;
}

div.facility-specification-wrapper h2 {
    padding: 7px 5px 3px;
}

ul.facility-specifications {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: 0;
    padding: 0;
}

ul.facility-specifications {
    overflow: hidden;
    height: 367px;
}

ul.facility-specifications>li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
}

ul.facility-specifications>li:nth-child(even) {
    background: #f3f3f3;
}

.strong {
    display: inline-block;
    font-weight: 700;
    font-family:  'Inter-Bold';
    text-align: left;
    padding: 6px 5px;
}

.strong.status {
    min-width: 100px;
}

.em {
    display: inline-block;
    text-align: right;
    line-height: initial;
    padding: 6px 5px;
    white-space: nowrap;
}

.em.status,
.em.specification {
    text-align: right;
    white-space: pre-wrap;
}

.strong.severity-name,
.em.severity-description,
.strong.specification,
.em.specification,
.strong.status,
.em.status,
.strong.tariff,
.em.tariff {
    padding: 5.8px 5px;
    margin: 0;
    word-wrap: break-word;   
    overflow-wrap: break-word;
    word-break: break-word;
}

.strong.specification{
    word-break:  keep-all;
}

.em.severity-description {
    padding-left: 20px;
}

span {
    font-size: 0.72rem;
}

#fac-info {
    padding: 4px 8px 5px;
    cursor: pointer;
}

#fac-status,
#fac-issues,
#fac-grid {
    padding: 4px 8px 5px;
    cursor: pointer;
}

.fac-view {
    background: white;
    border-top: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

ul.scroll-fac-spec {
    overflow: auto;
}

ul.scroll-fac-spec::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

ul.scroll-fac-spec::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

ul.scroll-fac-spec::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgb(208, 208, 208);
}

.invert-selects-grid-container {
    width: 100% !important;
    min-width: 280px;
    margin: unset !important;
    display: grid !important;
    grid-template-columns: minmax(100px, 110px) minmax(100px, 160px);
    justify-content: end;
}
.reversed-metering-col1, .reversed-metering-col2 {
    padding-right: 5px;
}


@media screen and (min-height: 800px) and (min-width: 1441px) {
    ul.facility-specifications {
        height: calc(100vh - 539px);
        max-height: calc(1500px - 539px);
    }
}

@media screen and (max-width: 1440px) {
    div.facility-specification-wrapper {
        margin: 2.5px auto;
    }

    div.facility-specification-wrapper {
        width: 100%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    ul.facility-specifications {
        align-items: stretch;
        height: 307px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    div.facility-specification-wrapper {
        max-width: 50%;
        min-width: 30%;
    }

    div.facility-specification-wrapper {
        margin: 2.5px 0;
    }
}

@media screen and (max-width: 768px) {
    div.facility-specification-wrapper {
        max-width: 100%;
        margin: 2.5px auto;
    }

    ul.facility-specifications {
        align-items: stretch;
        height: 380px;
    }
}

@media screen and (max-width: 380px) {
    .invert-selects-grid-container {
        grid-template-columns: 160px;
        min-width: unset;
    }
}

@media screen and (max-width: 370px) {
    div.command-btn-wrapper{
        position: relative;
        margin-right: 10px;
        right: 0px;
        padding-bottom: 3px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 5px;
    }
}