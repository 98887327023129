/* Copyright Levelise Ltd 2019-2023 */

div.fleet-table-title.facility-list {
    border-bottom: none;
    padding-bottom: 4px;
}

.facility-select-options {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.55rem;
    display: flex;
    justify-content: space-around;
    background: rgb(245, 245, 245);
    width: 100%;
}

.facility-select-options li {
    background: rgb(245, 245, 245);
    padding: 3px 0;
    width: -webkit-fill-available;
    width: -moz-available;
    cursor: pointer;
}

.facility-select-options li#right-space {
    background: rgb(245, 245, 245);
    padding: 0;
    width: 14px;
    cursor: none;
}

.facility-select-options li#dru.selected-option {
    background: white;
    border-bottom: white;
    border-right: 0.5px solid rgba(187, 187, 187, 0.33);
    border-top: 1px solid rgba(187, 187, 187, 0.33);
    border-top-right-radius: 3px;
}

.facility-select-options li#name.selected-option {
    background: white;
    border-bottom: white;
    border-right: 0.5px solid rgba(187, 187, 187, 0.33);
    border-left: 0.5px solid rgba(187, 187, 187, 0.33);
    border-top: 1px solid rgba(187, 187, 187, 0.33);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.facility-select-options li#id.selected-option {
    background: white;
    border-bottom: white;
    border-left: 0.5px solid rgba(187, 187, 187, 0.33);
    border-right: 0.5px solid rgba(187, 187, 187, 0.33);
    border-top: 1px solid rgba(187, 187, 187, 0.33);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.facility-select-options li.unselected-option {
    border-bottom: 1px solid rgba(187, 187, 187, 0.33);
}

.fleet-table-title.facility-list {
    display: flex;
    justify-content: space-between;
}

ul.facilities {
    margin: 0;
    padding: 0px;
    font-size: 0.75rem;
    list-style: none;
    text-align: left;
    height: 877px;
    overflow-y: scroll;
    overflow-x: auto;
    -webkit-overflow-y: scroll;
    -ms-overflow-style: none;
    top: 90px;
    bottom: 0;
}

.facilities-extended {
    margin: 0;
    padding: 0px;
    font-size: 0.75rem;
    list-style: none;
    text-align: left;
    overflow-y: scroll;
    overflow-x: auto;
    -webkit-overflow-y: scroll;
    -ms-overflow-style: none;
    top: 90px;
    bottom: 0;
}

ul.facilities li,
ul.facilities-extended li {
    padding: 4px 6px;
    border-bottom: 1px solid #e7e7e7;
    overflow: hidden;
}

ul.facilities li:last-of-type,
ul.facilities-extended li:last-of-type {
    /* border-bottom: none; */
}

ul.facilities li:nth-child(even),
ul.facilities-extended li:nth-child(even) {
    background: #f3f3f3;
}

ul.facilities li,
ul.facilities-extended li {
    display: block; 
    text-decoration: none;
    color: black;
}

ul.facilities li.facility-item,
ul.facilities-extended li.facility-item {
    cursor: pointer;
}

ul.facilities::-webkit-scrollbar-track,
ul.facilities-extended::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
  
ul.facilities::-webkit-scrollbar,
ul.facilities-extended::-webkit-scrollbar{
    width: 5px;
    background-color: #F5F5F5;
}
  
ul.facilities::-webkit-scrollbar-thumb,
ul.facilities-extended::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
} 
