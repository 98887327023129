/* Copyright Levelise Ltd 2025 */

.customers-id-list-container {
   height: calc(100% - 23px);
}

.customers-id-list::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.customers-id-list::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.customers-id-list::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}


.customers-id-list {
    grid-column: 4;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 50px;
    border-left: #f3f3f3 1px solid;
    list-style: none;
    padding: 0;
    margin: 0;
}

.customers-id-list li:nth-child(even) {
    background-color: #f3f3f3;
}

.customers-customers-id {
    list-style: none;
    text-align: left;
    padding-left: 2.5px;
    font-family: 'Inter-Bold';
    border-bottom: 1px solid #e7e7e7
}

.customers-customers-id:hover {
    cursor: pointer;
}


