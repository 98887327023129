/* Copyright Levelise Ltd 2024 */

.add-customer-container{
    padding: 42px 2.5px 0px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3px;
    grid-auto-rows: minmax(45vh, auto);
    overflow-y: auto;
}

.customer-search-container {
    grid-column: 1;
    grid-row: 1/3 ;
}

.facility-details-container {
    grid-column: 2 / 4;
    grid-row: 1;
}

.add-customer-address-container {
    grid-column: 2 / 4;
    grid-row: 2/3;
}

.old-customer-container {
    grid-column: 4 / 6;
    grid-row: 1;
}

.new-customer-container {
    grid-column: 4 / 6;
    grid-row: 2/3;
    overflow: hidden;
}

.container-border-style {
    border: 1px solid rgba(187, 187, 187, 0.33);
    border-radius: 3px;
}

.add-customer-container ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.add-customer-container ul::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.add-customer-container ul::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}

@media screen and (max-width: 1023px) {
    .add-customer-container{
        grid-template-columns: repeat(4, 1fr);
    }

    .facility-details-container {
        grid-column: 2 / 5;
    }
    
    .add-customer-address-container {
        grid-column: 2 / 5;
    }

    .old-customer-container {
        grid-column: 1 / 3;
        grid-row: 3;
    }
    
    .new-customer-container {
        grid-column: 3 / 5;
        grid-row: 3;
    }
}

@media screen and (max-width: 670px) {
    .old-customer-container {
        grid-column: 1 / 5;
        grid-row: 3;
    }
    
    .new-customer-container {
        grid-column: 1 / 5;
        grid-row: 4;
    }
}

@media screen and (max-width: 520px) {
    .add-customer-container{
        gap: 5px;
    }
    .customer-search-container {
        grid-column: 1 / 5;
        grid-row: 1/3 ;
    }

    .facility-details-container {
        grid-column: 1 / 5;
        grid-row: 3 ;
    }
    
    .add-customer-address-container {
        grid-column: 1 / 5;
        grid-row: 4 ;
    }

    .old-customer-container {
        grid-column: 1 / 5;
        grid-row: 5;
    }
    
    .new-customer-container {
        grid-column: 1 / 5;
        grid-row: 6;
    }
}
