/* Copyright Levelise Ltd 2021 */

#download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: none;
    cursor: pointer;
    right: 2px;
    top: 0px;
}

#download-btn.download,
#download-btn.loading {
    width: 18.5px;
    height: 18.5px;
    background: rgb(171, 46, 46);
    color: white;
    border-radius: 2px;
}

#download-btn.success {
    width: 18.5px;
    height: 18.5px;
    background: rgb(25, 127, 47);
    color: white;
    border-radius: 12px;
}
