/* Copyright Levelise Ltd 2024*/
ul.fleet-customer-list {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    list-style: none;
    text-align: left;
    height: calc(100vh - 190px);
    overflow-x: hidden;
    -webkit-overflow-y: hidden;
    -moz-overflow-y: hidden;
    -ms-overflow-style: none;
    
}

ul.fleet-customer-list li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.72rem;
    border-bottom: 1px solid #e7e7e7;
    overflow: hidden;
    min-height: 25px;
}

ul.fleet-customer-list li div {
    display: flex;
    justify-content: space-between;
    padding: 4px 6px;
    height: 20px;
}

ul.fleet-customer-list li div.af-name {
    cursor: pointer;
}

ul.fleet-customer-list li div.af-name.last-slot {
    padding: 3.5px 6px;
}

ul.fleet-customer-list > li:nth-child(even) {
    background: #f3f3f3;
}

ul.fleet-customer-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

ul.fleet-customer-list::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

ul.fleet-customer-list::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}

@media screen and (max-width: 960px) AND (min-width: 541px) {
    .label-input-container-on-fleet-customer {
        width: auto !important;
    }
}