/* Copyright Levelise Ltd 2020 */

.preferenceSix.specs-wrapper div.facility-specification-wrapper {
    width: 100%;
    margin: 2.5px 2.5px 0 0;
}

@media (min-width: 660px) and (max-width: 768px) {
    .preferenceSix div#top-right {
        width: 100%;
        display: flex;
    }
    
    .preferenceSix div#top-left {
        width: unset;
    }

    .preferenceSix div.live-status {
        min-width: 64%;
    }

    .preferenceSix .summary-chart { 
        margin: 2.5px 0 2.5px 2.5px;
        min-width: 35%;
        height: unset;
    }

    .preferenceSix .pie-chart-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .preferenceSix .pie-chart-wrapper div {
        width: 205px;
    }
}

@media (min-width: 769px) and (max-width: 1440px) {
    .preferenceSix.specs-wrapper div.facility-specification-wrapper {
        max-width: 100%;
        margin: 2.5px 0 0 0;
    }
}