/* Copyright Levelise Ltd 2020 */

@media screen and (min-width: 1441px) {
    .preferenceFour.specs-wrapper #top-section {
        flex-direction: column;
    }
    .preferenceFour div#top-right {
        width: 100%;
        padding-right: 2.5px;
    }

    .preferenceFour div#top-left {
        display: flex;
        width: 100%;
    }

    .preferenceFour div.live-status {
        margin: 2.5px auto 0;
    }

    .preferenceFour {
        height: 390px; 
        width: 520px;
        margin: 0;
    } 

    .preferenceFour .summary {
        width: 100%;
        max-height: 456px;
        margin: 2.5px 2.5px 2.5px 0px;
    }

    .preferenceFour .summary-chart {
        width: 50%;
        max-height: 456px;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 auto;
    }

    .preferenceFour .strong,
    .preferenceFour .em {
        padding: 5px;
    }
/* 
    .preferenceFour div.energy-sums-wrapper {
        height: 493px;
        width: 236px;
    } */
}

@media screen and (max-width: 1440px) { 

}

@media (min-width: 769px) and (max-width: 1024px) {

} 
