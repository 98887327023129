/* Copyright Levelise Ltd 2025 */

.customers-tariffs-filter-list {
   height: calc(100% - 23px);
}

.customers-tariffs-filter-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

 .customers-tariffs-filter-list::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.customers-tariffs-filter-list::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}

.customers-tariff-filter-container {
    grid-column: 1/4;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 50px;
}

.tariff-directions-container {
    display: flex;
    background-color: rgb(245, 245, 245);
}

.sticky-header {
    position:sticky;
    top: 0
}

.providers-list-item {
    padding-left: 2.5px;
}

.directions-tab {
    flex: 1;
    margin-top: 3px;
    border-bottom: rgba(187, 187, 187, 0.33) 1px solid;
    padding-bottom: 2px;
    transition: background-color .5s;
}

.directions-tab.selected {
    border-top: rgba(187, 187, 187, 0.33) 1px solid;
    background-color: white;
    border: rgba(187, 187, 187, 0.33) 1px solid;
    border-bottom-width: 0;
}

.directions-tab {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.directions-tab:first-child {
    border-top-left-radius: 0;
    border-left-width: 0;
}
.directions-tab:last-child {
    border-top-right-radius: 0;
    border-right-width: 0;
}

.directions-tab {
    text-transform: uppercase;
}

.directions-tab:hover {
    cursor: pointer;
}

.capture-types-list {
    list-style: none;
    text-align: left;
    padding: 0;
    margin-top: 0;
}

.capture-method-list-item {
    display: flex;
    align-items: center;
    padding-left: 4px;
    border-bottom: 1px solid #e7e7e7;
    min-height: 22px;
}

.capture-type-title-count {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.capture-types-list li:nth-child(even) {
    background-color: #f3f3f3;
}
.capture-method-list-con:nth-child(even) {
    background-color: #f3f3f3;
}

.capture-type-name {
    text-transform: capitalize;
    font-family: 'Inter-Bold';
}

.capture-type-name-medium {
    text-transform: capitalize;
    font-family: 'Inter-Medium';
}

.capture-types-list li span:first-child::first-letter {
    text-transform: uppercase;
}

.capture-types-list li:hover {
    cursor: pointer;
}

.capture-types-list li span {
    font-size: .72rem;
    min-height: 20px;
    display: flex;
    align-items: center;
}

.customers-tariff-count {
    padding-right: 5px;
}

.customers-capture-type-count {
    padding-right: 5px;
    font-family: 'Inter-Bold';
}

.customers-capture-type-count {
    padding-right: 5px;
    font-family: 'Inter-Bold';
}

.capture-types-list li.selected {
    background-color: rgba(130,202,198, 1);
}

.providers-list {
    list-style: none;
    padding: 0;
    margin-top: 0;
    padding-left: 20px;
}

.providers-list li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7
}
