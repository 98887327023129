/* Copyright Levelise Ltd 2025 */

.customers-consent-filter-list {
   height: calc(100% - 23px);
}

.customers-consent-filter-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

 .customers-consent-filter-list::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.customers-consent-filter-list::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(208, 208, 208);
}

.gsa-version-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.gsa-version-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 20px;
    padding-left: 2.5px;
}

.gsa-version-list li:nth-child(even) {
    background-color: #f3f3f3;
}

.consent-progress-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.consent-progress-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 22px;
    padding-left: 2.5px;
    border-bottom: 1px solid #e7e7e7
}

.consent-progress-list li:nth-child(even) {
    background-color: #F3F3F3;
}

.consent-progress-name::first-letter {
   text-transform: capitalize;
   
}

.consent-progress-list h6 {
    margin: 0;
    padding: 1px;
    text-align: left;
    background-color: #f3f3f3;
}

.gsa-version-list li.selected {
    background-color: rgba(130,202,198, 1);
}

.no-text-transform {
    text-transform: none;
}

.consent-progress-list li.selected {
    background-color: rgba(130,202,198, 1);
}

.consent-progress-list li:hover {
    cursor: pointer;
}

.customers-consent-count {
    padding-right: 5px;
}
