/* Copyright Levelise Ltd 2019 */
#not-found-page,
#error-page {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#not-found-page p,
#not-found-page p a,
#error-page p,
#error-page p a  {
    margin: 3px;
    color: black;
}