/* Copyright Levelise Ltd 2023*/
.search-inputs-container {
    background: rgb(245, 245, 245);
    padding: 15.5px 5px 6.5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.label-input-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 5.6px;
}

.label-input-container input{
    max-width: 170px ;
    /* margin: 5px; */
    padding-left: 4px;
    min-width: 162px;
}

.label-input-container label{
    font-size: 0.7rem;
    font-family:  'Inter-Bold';
    padding-right: 5px;
    text-align: end;
}

.customer-search-button-container {
    display: flex;
    justify-content: flex-end;
    /* margin: 5px; */
}

button.configure-btn{
    margin: 5.6px 0px 5.5px 4px !important;
}

.subtitle{
    padding-left: 5px;
    padding-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customer-list-item-div {
    display: flex;
    height: unset !important;
}

.li-wrapper-for-customer-list {
    display: flex;
    flex-direction: row;
}


@media screen and (max-width: 960px) {
    .search-inputs-container {
       align-items: center;
    }
    .label-input-container {
        width: 85%;
    }

    .customer-search-button-container {
        width: 85%;
    }

    .label-input-container input{
        max-width: none ;
        flex-grow: 1;
    }
    
    .label-input-container label{
        width: 60px;
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 245px) {
    .label-input-container {
        width: 100%;
    }

    .customer-search-button-container {
        width: 100%;
    }
}

@media screen and (max-width: 230px) {
    .label-input-container label{
        display: none;
    }
}
