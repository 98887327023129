/* Copyright Levelise Ltd 2024 */

#add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: none;
    cursor: pointer;
    right: 0px;
    top: 0px;
}

#add-btn.add {
    width: 18.5px;
    height: 18.5px;
    background: #028eaf;
    color: white;
    border-radius: 2px;
}
