/* Copyright Levelise Ltd 2024 */

#upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: none;
    cursor: pointer;
    right: 2px;
    top: 0px;
}

#upload-btn.upload,
#upload-btn.loading {
    width: 18.5px;
    height: 18.5px;
    background: #028eaf;
    color: white;
    border-radius: 2px;
}

#upload-btn.success {
    width: 18.5px;
    height: 18.5px;
    background: rgb(25, 127, 47);
    color: white;
    border-radius: 12px;
}
